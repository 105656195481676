import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';

const DuplicationFicheGrueEntete = ({
  onChange,
  distincts,
  fg,
  fg: {
    marque,
    modele,
    serie,
    type1,
    origine,
    piecesjointes,
    commentaire,
    archive,
    liaison,
    serialnumber,
    anneeprod,
    infovue,
    typemc600,
    index,
    nbs,
    module,
    date
  }
}) => {
  // const [singleSelections, setSingleSelections] = useState([[], []]);
  const [singleSelections, setSingleSelections] = useState([
    [marque ? marque : ''],
    [modele ? modele : ''],
    [serialnumber ? serialnumber : ''],
    [type1 ? type1 : ''],
    [origine ? origine : ''],
    [liaison ? liaison : ''],
    [serie ? serie : ''],
    [anneeprod ? anneeprod : ''],
    [infovue ? infovue : ''],
    [index ? index : ''],
    [nbs ? nbs : ''],
    [module ? module : '']
  ]);

  const listparam = [
    'marque',
    'modele',
    'serialnumber',
    'type1',
    'origine',
    'liaison',
    'serie',
    'anneeprod',
    'infovue',
    'indexgrue',
    'nbs',
    'module'
  ];

  const listLabelParams = [
    'Marque',
    'Modèle',
    'Serial number',
    'Type',
    'Origine',
    'Liaison',
    'Série',
    'Année de production',
    'Infovue',
    'Index grue',
    'Nb dents couronne',
    'Module'
  ];

  useEffect(() => {
    // if (marque && marque !== '')
    // setSingleSelections([[marque], [modele], [serie], [type1]]);
    // console.log('useEffect marque : ' + marque);
    // }, [distincts]);
  }, [marque, modele, serie, type1]);

  function onSelection(tab, index) {
    // console.log(tab);
    let arrayTampon = [...singleSelections];
    arrayTampon[index] = tab;
    setSingleSelections(arrayTampon);
    onChange(tab, listparam[index]);
  }

  function inputChangeLibre(texte, index) {
    // console.log('input change' + texte);
    onChange([texte], listparam[index]);
  }

  // console.log(distincts);
  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <div style={headStyle}>Détails fiche</div>
      <Fragment>
        {listparam.map((elt, index) => (
          <form class="row col-12">
            <label style={labelStyle} class="col-2">
              {listLabelParams[index]}
            </label>
            <div class="col-10" style={{ marginTop: 1 }}>
              <Typeahead
                id={`${elt}`}
                labelKey={elt}
                onChange={(tab) => onSelection(tab, index)}
                onInputChange={(texte) => inputChangeLibre(texte, index)}
                options={distincts[index]}
                placeholder={`Renseigner ${listLabelParams[index]}`}
                // placeholder={singleSelections[index]}
                selected={singleSelections[index]}
              />
            </div>
          </form>
        ))}
      </Fragment>
    </div>
  );
};

DuplicationFicheGrueEntete.propTypes = {
  fichegrue: PropTypes.object.isRequired,
  distincts: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default DuplicationFicheGrueEntete;

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};

const labelStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
