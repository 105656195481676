import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ModalDemandeModification from '../demandeModifFG/ModalDemandeModification';
import { MdMode } from 'react-icons/md';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFichegrue } from '../../actions/fichegrue';

const BoutonDemandeModification = ({
  createFichegrue,
  fichegrue: { fichegrue, loading },
  auth
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const initialState = {
    demandes_modification: []
  };
  const [copieLocalDemandesModification, setCopieLocalDemandesModification] =
    useState(initialState);
  const [show, setShow] = useState(false);
  const openModalUser = () => setShow(true);
  const closeModalUser = () => setShow(false);

  useEffect(() => {
    if (loading === false && fichegrue !== null) {
      setCopieLocalDemandesModification([...fichegrue.demandes_modification]);
    } else {
      setCopieLocalDemandesModification([]);
    }
  }, [loading, fichegrue]);
  function nouvelleDemande(textData) {
    const clone = [...copieLocalDemandesModification];
    setCopieLocalDemandesModification(
      clone.push({
        auteur: { _id: auth.user._id },
        texte: textData,
        status: 'Cree'
      })
    );
    const formDataAEnvoyer = {
      demandes_modification: clone
    };
    // Cette fonction permet de créer une nouvelle demande en prenant l'identifiant de l'utilisateur et le texte de la demande de modification.
    //Navigate est utilisé pour éviter le rafraîchissement de la page, et le paramètre false indique que ça ne concerne pas les nomenclatures.
    createFichegrue(id, formDataAEnvoyer, navigate, false, false, false);
  }

  return (
    <>
      <button
        className="btn btn-primary btn-lg"
        style={{
          marginTop: 20,
          marginBottom: 50
        }}
        onClick={() => openModalUser()}
      >
        demande de modification <MdMode />
      </button>
      {show && (
        <ModalDemandeModification
          show={show}
          closeModalUser={closeModalUser}
          nouvelleDemande={nouvelleDemande}
        />
      )}
    </>
  );
};

BoutonDemandeModification.propTypes = {
  auth: PropTypes.object.isRequired,
  fichegrue: PropTypes.object.isRequired,
  createFichegrue: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createFichegrue
})(BoutonDemandeModification);
