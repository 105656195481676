import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaPrint } from 'react-icons/fa';
import { getFichegrueById, deleteFichegrue } from '../../actions/fichegrue';
// import { getOptionByParameter } from '../../actions/optionfichegrue';
import { getOptions } from '../../actions/optionfichegrue';
// import ItemListePieces from './ItemListePieces';
import DetailFicheGrueBom from './DetailFicheGrueBom';
import DetailFicheGrueEntete from './DetailFicheGrueEntete';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Spinner from '../layout/Spinner';
import Rapport from '../rapportPDF/Rapport';
import moment from 'moment';
import 'moment/locale/fr';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MdDelete } from 'react-icons/md';
import { MdMode } from 'react-icons/md';
import { FaCopy } from 'react-icons/fa';
import AffichageDrapeau from '../drapeauValidation/AffichageDrapeau';
import InteractionDemandesModification from '../demandeModifFG/InteractionDemandesModification';
import BoutonDemandeModifications from '../demandeModifFG/BoutonDemandeModificaction';

const DetailFicheGrue = ({
  getFichegrueById,
  deleteFichegrue,
  // getOptionByParameter,
  // getOptions,
  fichegrue: { fichegrue, loading },
  // optionfichegrue: { options, loadingopt },
  auth
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getFichegrueById(id, true);
    // getOptions();
  }, [getFichegrueById, id]);

  const [client, setClient] = useState('');
  const [adresse, setAdresse] = useState('');
  const [ville, setVille] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [commentairepdf, setCommentairePDF] = useState('');
  const [testOptions, settestOptions] = useState('[]');
  const [greenFlagGeneratePDF, setGreenFlagGeneratePDF] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const MettreAJourPageListeDrapeau = () => {
    setRefresh(!refresh);
  };

  const EffacementFicheGrue = () => {
    if (
      window.confirm(
        'Etes vous sur ? Cette opération ne peut pas être annulée !'
      )
    ) {
      deleteFichegrue(fichegrue._id);
      navigate(-1);
    }
  };

  const cocheOptions = (options) => {
    console.log(options.length);
    console.log(fichegrue.options.length);
    console.log(fichegrue.options);
    return options.length > 0 ? (
      options.map((elt, index) =>
        elt === null || elt.refx3 === null ? (
          <div key={index}></div>
        ) : (
          <div className="col-12" key={index}>
            <input
              className="form-check-input "
              type="checkbox"
              checked={options[index].util === true}
              onChange={() => toggleUtilOptions(index)}
            />
            <label className="form-check-label text-detail-coche-option">
              {elt.nom}
            </label>
          </div>
        )
      )
    ) : (
      <div></div>
    );
  };

  const toggleUtilOptions = (indexOption) => {
    const old = testOptions[indexOption];
    const updated = { ...old, util: !testOptions[indexOption].util };
    const clone = [...testOptions];
    clone[indexOption] = updated;
    settestOptions(clone);
  };

  useEffect(() => {
    if (loading === false && fichegrue !== null) {
      settestOptions([...fichegrue.options]);
    } else {
      settestOptions([]);
    }
  }, [loading, fichegrue]);

  if (loading) {
    return <Spinner />;
  } else if (fichegrue === null) {
    return (
      <div style={{ marginTop: 100, marginBottom: 50 }}>
        <h3 className="text-center">Aucune fiche grue n'a été trouvée</h3>
      </div>
    );
  } else if (
    fichegrue !== null &&
    testOptions.length === fichegrue.options.length
  ) {
    // console.log(testOptions);
    const {
      // _id : id,
      // author: { _id: author_id, name },
      marque,
      modele,
      // serie,
      // type1,
      // origine,
      piecesjointes,
      // bom,
      commentaire,
      archive
      // demandes_modification
    } = fichegrue;

    var nomPDF = `fiche grue ${marque}   ${modele}   ${moment().format(
      'MMMM Do YYYY, HH:mm:ss'
    )}.pdf`;

    return (
      <section className="container">
        <Fragment>
          <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
            Kit MC{fichegrue.typemc600} - Preparation fiche imprimable
          </h4>
          <AffichageDrapeau
            valide={fichegrue.valide}
            ID={fichegrue._id}
            drapeauListe={false}
            setRefresh={MettreAJourPageListeDrapeau}
          />
          <InteractionDemandesModification />
          {/* info client */}
          <div style={{ marginTop: 20 }}>
            <h4>Information Client</h4>
            <form>
              <div className="form-group">
                <label style={{ fontSize: '1.1rem', marginBottom: 10 }}>
                  Client:
                </label>
                <input
                  type="text"
                  className="form-control "
                  value={client}
                  onChange={(e) => setClient(e.target.value)}
                  placeholder=" Jean BON"
                />
              </div>

              <div className="form-group" style={{ marginBottom: 10 }}>
                <label
                  style={{
                    fontSize: '1.1rem',
                    marginBottom: 10,
                    marginTop: 20
                  }}
                >
                  Adresse:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={adresse}
                  onChange={(e) => setAdresse(e.target.value)}
                  placeholder="Rue, avenue..."
                />
              </div>

              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control "
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                    placeholder=" Ville"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    value={codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    placeholder=" Code postal"
                  />
                </div>
              </div>

              <div className="form-group" style={{ marginTop: 10 }}>
                {/* <div className="form-check">{cocheOptions(testOptions)}</div> */}
                <div className="form-check">{cocheOptions(testOptions)}</div>
              </div>

              <DetailFicheGrueEntete fichegrue={fichegrue} />

              <div style={{ marginTop: 50 }} className="card">
                <div style={headStyle} className="card-header">
                  Pièces jointes :
                </div>
                <ListePiecesJointes
                  tailleTexte="normal"
                  listPJ={piecesjointes}
                />
              </div>

              {/* <DetailFicheGrueBom fichegrue={fichegrue} options={testOptions} /> */}
              <DetailFicheGrueBom fichegrue={fichegrue} options={testOptions} />

              <div style={{ marginTop: 30 }}>
                <h4 htmlFor="comment">Commentaires impression:</h4>
                <textarea
                  className="form-control"
                  rows="4"
                  id="comment"
                  placeholder="Renseigner vos commentaires ici"
                  onChange={(e) => setCommentairePDF(e.target.value)}
                ></textarea>
              </div>
            </form>
          </div>
          {/* <div style={{ marginTop: 50 }}>
          <div style={{ marginTop: 30, marginBottom: 50 }}>
            <h4>Commentaire :</h4>
            <p>{commentaire}</p>
            <h4 style={{ marginTop: 30 }}>Archive :</h4>
            <p>{archive}</p>
          </div>
        </div> */}

          {commentaire && commentaire.length > 0 ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ marginTop: 30, marginBottom: 50 }}>
                <h4>Commentaire fiche grue :</h4>
                <p>{commentaire}</p>
                <h4 style={{ marginTop: 30 }}>Archive :</h4>
                <p>{archive}</p>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div style={{ marginBottom: 50 }} className="row">
            <div className="col-4 text-center">
              {fichegrue.effacable === true ? (
                <button
                  className="btn btn-warning btn-lg float-left"
                  style={{
                    marginLeft: 100,
                    marginTop: 20,
                    marginBottom: 50
                  }}
                  onClick={EffacementFicheGrue}
                >
                  Effacer fiche grue <MdDelete />
                </button>
              ) : (
                <div></div>
              )}
              {/* </Link> */}
            </div>

            <div className="col-2 text-center">
              <Link to={`/creation/${id}`}>
                <button
                  className="btn btn-primary btn-lg"
                  style={{
                    // marginLeft: 100,
                    marginTop: 20,
                    marginBottom: 50
                  }}
                >
                  Dupliquer <FaCopy />
                </button>
              </Link>
            </div>
            <div className="col-3 text-center">
              {auth.user.rights === 'admin' ? (
                <Link
                  to={`/modificationfichegrue/${fichegrue.typemc600}/${id}/true`}
                >
                  <button
                    className="btn btn-primary btn-lg"
                    style={{
                      marginTop: 20,
                      marginBottom: 50
                    }}
                  >
                    Modifier <MdMode />
                  </button>
                </Link>
              ) : (
                <>
                  <BoutonDemandeModifications />
                </>
              )}
            </div>

            {/* Pour contournement probleme generation PDF à chaque re-render */}
            {/* TODO utiliser methode sur page pour regler probleme : https://github.com/diegomura/react-pdf/issues/608 */}
            {greenFlagGeneratePDF === false ? (
              <div className="col-2 text-center">
                <button
                  className="btn btn-primary float-right btn-lg"
                  style={{
                    // marginRight: 100,
                    marginTop: 20,
                    marginBottom: 50
                  }}
                  onClick={() => setGreenFlagGeneratePDF(true)}
                >
                  Preparer PDF <FaPrint />
                </button>
              </div>
            ) : (
              <div className="col-2 text-center">
                <PDFDownloadLink
                  document={
                    <Output
                      fichegrue={fichegrue}
                      options={testOptions}
                      client={client}
                      email=""
                      phone=""
                      adresse={adresse}
                      ville={ville}
                      codepostal={codePostal}
                      commentairepdf={commentairepdf}
                    />
                  }
                  fileName={nomPDF}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Loading pdf document...'
                    ) : (
                      <button
                        className="btn btn-primary float-right btn-lg"
                        style={{
                          // marginRight: 100,
                          marginTop: 20,
                          marginBottom: 50
                        }}
                      >
                        Générer fiche <FaPrint />
                      </button>
                    )
                  }
                </PDFDownloadLink>
              </div>
            )}
          </div>
        </Fragment>
      </section>
    );
  } else {
    return <Spinner />;
  }
};

DetailFicheGrue.propTypes = {
  auth: PropTypes.object.isRequired,
  fichegrue: PropTypes.object.isRequired,
  optionfichegrue: PropTypes.object.isRequired,
  deleteFichegrue: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  optionfichegrue: state.optionfichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getFichegrueById,
  deleteFichegrue,
  getOptions
})(DetailFicheGrue);

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};

moment.locale('fr');
const Output = ({
  fichegrue,
  options,
  client,
  email,
  phone,
  adresse,
  ville,
  codepostal,
  commentairepdf
}) => {
  const rapport = {
    client: client,
    email: email,
    phone: phone,
    adresse: adresse,
    ville: ville,
    codepostal: codepostal,
    commentairepdf: commentairepdf,
    // date_generation: '1',
    date_generation: moment().format('Do MMMM YYYY, HH:mm:ss'),
    fichegrue,
    options
  };

  return <Rapport rapport={rapport} />;
};
