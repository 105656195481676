import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getFichegrueById,
  createFichegrue,
  getFichegrueByCustomParameters
} from '../../actions/fichegrue';
import { useParams, useNavigate } from 'react-router-dom';

import { getPiecesBySeq } from '../../actions/piece';
import Spinner from '../layout/Spinner';
import ItemDuplication from '../duplication/ItemDuplication';
import ModalImportPJ from '../duplication/ModalImportPJ';
import ItemAjoutNew from '../duplication/ItemAjoutNew';
import DuplicationFicheGrueEntete from '../duplication/DuplicationFicheGrueEntete';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import { getDistinctRefreshAll } from '../../actions/fichegrue';
import { FaSave } from 'react-icons/fa';
import { TiArrowBackOutline } from 'react-icons/ti';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { getOptions } from '../../actions/optionfichegrue';
import { setAlert } from '../../actions/alert';

const DuplicationFicheGrue = ({
  setAlert,
  getFichegrueByCustomParameters,
  getFichegrueById,
  createFichegrue,
  getPiecesBySeq,
  getDistinctRefreshAll,
  fichegrue: { fichegrue, fichesgrues, loading, update, distincts },
  piece: { pieces, loadingpiece },
  getOptions,
  optionfichegrue: { options, loadingopt },
  auth
}) => {
  const { id, typeMC600, modification } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== '0') {
      getFichegrueById(id);
    }
    // TM on demande la liste des kit de base à chaque fois, on filtre sur l'affichage une fois toutes les infos reçues
    getFichegrueByCustomParameters(
      undefined,
      typeMC600 === '602' ? 'kitbaseMC602' : 'kitbaseMC603',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    getPiecesBySeq(typeMC600 === '602' ? '602' : '603');
    getOptions(typeMC600 === '602' ? '602' : '603');
    getDistinctRefreshAll();
    // console.log(typeMC600);
  }, [
    getFichegrueById,
    id,
    typeMC600,
    getPiecesBySeq,
    getFichegrueByCustomParameters,
    getOptions,
    getDistinctRefreshAll
  ]);

  const [flagSignalVariantes, setFlagSignalVariantes] = useState(false);
  const [flagKitBase, setFlagKitBase] = useState(false);
  const [listSeq, setListSeq] = useState([]);
  const [newBOM, setNewBom] = useState([]);
  const [newPieces, setNewPieces] = useState([]);
  const [listKitBase, setListKitBase] = useState([]);
  const [newOptions, setnewOptions] = useState([]);
  const [IndexKitBaseSelected, setIndexKitBaseSelected] = useState(-1);
  const [listPiecesforNew, setListPiecesforNew] = useState([]);
  const [listPiecesParSeq, setListPiecesParSeq] = useState([]);
  const [dataNomenclatureCom, setDataNomenclatureCom] = useState('');

  const [formDataFicheGrue, setFormDataFicheGrue] = useState({
    marque: '',
    modele: '',
    serie: '',
    type1: '',
    origine: '',
    piecesjointes: [],
    commentaire: '',
    archive: '',
    liaison: '',
    serialnumber: '',
    anneeprod: '',
    infovue: '',
    typemc600: '',
    index: '',
    nbs: '',
    module: '',
    effacable: true,
    nomenclature_com: ''
  });

  useEffect(() => {
    if (loading === false && loadingpiece === false && loadingopt === false) {
      let items = [];
      let clone;
      if (fichegrue !== null && listPiecesParSeq.length !== 0) {
        // const clone = fichegrue.bom.slice();
        clone = fichegrue.bom.map((i) => ({ ...i }));
        clone.sort((a, b) => a.seq - b.seq);
        // setNewBom(clone);
        // const clone2 = [...clone];

        //recherche des seq avec une seul piece dans newBOM, on affichera directement l'element dans le select, mais il faut aussi remplacer la refx3 dans la BOM
        // console.log(pieces);
        let presenceS120 = false;
        clone.forEach((elt, index) => {
          if (
            listPiecesParSeq[elt.seq / 10 - 1] &&
            listPiecesParSeq[elt.seq / 10 - 1].length === 1 &&
            elt.piece !== null
          ) {
            const old = clone[index].piece;
            const updated = {
              ...old,
              nom: listPiecesParSeq[elt.seq / 10 - 1][0].nom,
              refx3: listPiecesParSeq[elt.seq / 10 - 1][0].refx3
            };
            clone[index].piece = updated;
            clone[index].variantepossible = false;
            // console.log(index + ' ' + pieces[elt.seq / 10 - 1][0].refx3);
          } else if (
            elt.piece !== null &&
            elt.piece.refx3 !== null &&
            modification === undefined
          ) {
            // pour ceux avec un choix multiple, on met la ref a null pour pouvoir passer le background en couleur si pas de choix et demande de creation fiche
            // mais on ne le fait pas dans le cas d'une modification, on garde ts les elements de la fiche grue
            const old = clone[index].piece;
            const updated = {
              ...old,
              refx3: '',
              nom: null
            };
            clone[index].piece = updated;
            clone[index].variantepossible = true;
          }
          clone[index].piecesjointes = [];
          // si on duplique une fiche et que la fiche de base ne contenait pas la seq 120, on la rajoute (pb de base de données source incomplete)
          if (elt.seq === 120 && elt.piece !== null) {
            presenceS120 = true;
          }
        });
        // la grosse verrue

        if (
          !presenceS120 &&
          fichegrue !== null &&
          id !== '0' &&
          modification === undefined
        ) {
          if (listPiecesParSeq[120 / 10 - 1].length === 1) {
            clone.push({
              _id: '111111111111111111111111',
              piecesjointes: [],
              piece: {
                refx3: pieces[120 / 10 - 1][0].refx3,
                nom: pieces[120 / 10 - 1][0].nom
              },
              qte: 1,
              seq: 120,
              variantepossible: false
            });
          } else {
            clone.push({
              _id: '111111111111111111111111',
              piecesjointes: [],
              piece: {
                refx3: '',
                nom: ''
              },
              qte: 1,
              seq: 120,
              variantepossible: true
            });
          }
        }
        setNewBom(clone);

        // //TEST passage des select en rouge si rien de selectionné
        // newBOM.map((elt, index) =>{
        // if (elt.piece !== null && pieces[elt.seq / 10 - 1].length > 1){
        //   supprimePieceNewBom(index);
        // }};

        if (
          fichegrue.modele === 'kitbaseMC603' ||
          fichegrue.modele === 'kitbaseMC602'
        ) {
          fichegrue.marque = '';
          fichegrue.modele = '';
          fichegrue.serie = '';
          setFlagKitBase(true);
        }

        const typemc = typeMC600 === '602' ? 602 : 603;

        //Si duplication, certains champs sont raz: Si modification, on les affiche
        if (modification) {
          setFormDataFicheGrue({
            ...fichegrue,
            bom: null,
            effacable: true,
            typemc600: typemc
          });
        } else {
          setFormDataFicheGrue({
            ...fichegrue,
            bom: null,
            commentaire: '',
            effacable: true,
            typemc600: typemc,
            piecesjointes: [],
            valide: false
          });
        }
      }
      if (fichesgrues.length > 0) {
        //remplissage de la liste des kits de base
        items = [];
        fichesgrues.forEach((element) => {
          items.push({
            nomenclature_com: element.nomenclature_com,
            id: element._id,
            serie: element.serie
          });
        });
        setListKitBase(items);
      }
      items = [];
      for (let i = 1; i <= listPiecesParSeq.length; i++) {
        items.push(i * 10);
      }
      setListSeq(items);

      //recuperation de la liste des options et mise en place variantepossible
      // le tri des options et options bom est fait plus bas directement dans l'affichage
      const cloneoptions = options.map((i) => ({ ...i }));

      cloneoptions.forEach((eltOpt, indexOpt) => {
        const clone1optionbom = eltOpt.bom.map((i) => ({ ...i }));
        clone1optionbom.forEach((eltBOM, indexBOM) => {
          //recherche des pieces en options avec variante possible et marquage
          if (
            listPiecesParSeq[eltBOM.seq / 10 - 1] &&
            listPiecesParSeq[eltBOM.seq / 10 - 1].length === 1 &&
            eltBOM.piece !== null
          ) {
            clone1optionbom[indexBOM].variantepossible = false;
          } else if (eltBOM.piece !== null && eltBOM.piece.refx3 !== null) {
            clone1optionbom[indexBOM].variantepossible = true;
          }
          // recherche des sequences deja presente dans la nomenclature de base,
          // marquage pour signalement visuel et suppression lors de l'enregistrement fiche
          if (eltBOM.piece && eltBOM.seq && clone) {
            if (
              clone.some(
                (piecebase) => piecebase.seq === eltBOM.seq && piecebase.piece
              )
            ) {
              eltBOM.seqEnDouble = true;
              console.log(eltOpt.nom + ' | ' + eltBOM.nom);
            } else {
              eltBOM.seqEnDouble = false;
            }
          }
        });

        cloneoptions[indexOpt].bom = clone1optionbom;
      });
      setnewOptions(cloneoptions);
    } else {
      //reset de la page sur loading  = true
      setListSeq([]);
      setNewBom([]);
      setNewPieces([]);
      setnewOptions([]);
      setFormDataFicheGrue({
        marque: '',
        modele: '',
        serie: '',
        type1: '',
        origine: '',
        piecesjointes: [],
        commentaire: '',
        archive: '',
        liaison: '',
        serialnumber: '',
        anneeprod: '',
        infovue: '',
        typemc600: '',
        index: '',
        nbs: '',
        module: '',
        effacable: true
      });
    }
  }, [
    loading,
    loadingpiece,
    loadingopt,
    fichegrue,
    fichesgrues,
    listPiecesParSeq,
    pieces,
    options
  ]);

  useEffect(() => {
    if (pieces.length !== 0 && fichegrue !== null) {
      const clone = [];
      pieces.forEach((elt) => {
        elt.forEach((piece) => {
          clone.push(piece);
        });
      });
      setListPiecesforNew(clone);

      const clone1 = [];
      pieces.forEach((elt, index) => {
        const clone2 = [];
        elt.forEach((piece) => {
          if (
            !fichegrue.pieces_exclues_nomcom_v2.some(
              (e) => e.piece._id === piece._id && e.seq === (index + 1) * 10
            )
          ) {
            clone2.push(piece);
          }
        });
        clone1.push(clone2);
      });
      setListPiecesParSeq(clone1);
    }
  }, [pieces, fichegrue]);

  useEffect(() => {
    if (fichegrue !== null && update === true) {
      navigate(`/fichegrue/${fichegrue._id}`);
    }
  }, [update, fichegrue]);

  const onChangeDataFicheGrue = (e, name) => {
    setFormDataFicheGrue({
      ...formDataFicheGrue,
      [name]: e[0]
    });
  };

  const updateItemNewBom = (prop, event, index) => {
    const old = newBOM[index];
    const updated = { ...old, [prop]: parseInt(event.target.value, 10) };
    const clone = [...newBOM];
    clone[index] = updated;
    setNewBom(clone);
  };

  const updateItemNvellePiece = (prop, event, index) => {
    const old = newPieces[index];
    const updated = { ...old, [prop]: parseInt(event.target.value, 10) };
    const clone = [...newPieces];
    clone[index] = updated;
    setNewPieces(clone);
  };

  const updateItemNewOptions = (prop, event, indexOption, indexBOM) => {
    const old = newOptions[indexOption].bom[indexBOM];
    const updated = { ...old, [prop]: parseInt(event.target.value, 10) };
    const clone = [...newOptions];
    clone[indexOption].bom[indexBOM] = updated;
    setnewOptions(clone);
  };

  const updateRefX3ItemNewOptions = (event, indexOption, indexBOM) => {
    const old = newOptions[indexOption].bom[indexBOM].piece;
    const updated = { ...old, refx3: event.target.value };
    const clone = [...newOptions];
    clone[indexOption].bom[indexBOM].piece = updated;
    setnewOptions(clone);
  };

  const updateRefX3ItemNewBom = (event, index) => {
    const old = newBOM[index].piece;
    const updated = { ...old, refx3: event.target.value };
    const clone = [...newBOM];
    clone[index].piece = updated;
    setNewBom(clone);
  };

  const updateRefX3ItemNewPieces = (newRefx3, index) => {
    const old = newPieces[index].piece;
    const updated = { ...old, refx3: newRefx3 };
    const clone = [...newPieces];
    clone[index].piece = updated;
    setNewPieces(clone);
  };

  const updatePJItemNewOptions = (list, indexOption, indexBOM) => {
    const old = newOptions[indexOption].bom[indexBOM];
    const updated = { ...old, piecesjointes: list };
    const clone = [...newOptions];
    clone[indexOption].bom[indexBOM] = updated;
    setnewOptions(clone);
  };

  const updatePiecejointeItemNewBom = (list, index) => {
    const old = newBOM[index];
    const updated = { ...old, piecesjointes: list };
    const clone = [...newBOM];
    clone[index] = updated;
    setNewBom(clone);
  };

  const updatePJItemNewpieces = (list, index) => {
    const old = newPieces[index];
    const updated = { ...old, piecesjointes: list };
    const clone = [...newPieces];
    clone[index] = updated;
    setNewPieces(clone);
  };

  const DeleteItemNewBom = (index) => {
    const old = newBOM[index];
    const updated = { ...old, piece: null };
    const clone = [...newBOM];
    clone[index] = updated;
    setNewBom(clone);
  };

  //on efface pas la piece, on la passe en invisible pour eviter de devoir rafraichir tt les composants ItemAjoutNew
  // piece =  null garantie la non-creation à la fin
  const DeleteItemNewPieces = (index) => {
    const old = newPieces[index];
    const updated = { ...old, piece: null, seq: null, visible: false };
    const clone = [...newPieces];
    clone[index] = updated;
    setNewPieces(clone);
  };

  const DeleteItemNewOptions = (indexOption, indexBOM) => {
    const old = newOptions[indexOption].bom[indexBOM];
    const updated = { ...old, piece: null };
    const clone = [...newOptions];
    clone[indexOption].bom[indexBOM] = updated;
    setnewOptions(clone);
  };

  const DeleteOptionComplete = (indexOption) => {
    const clone = [...newOptions];
    newOptions[indexOption].bom.forEach((eltBOM, indexBOM) => {
      const old = eltBOM;
      const updated = { ...old, piece: null };
      clone[indexOption].bom[indexBOM] = updated;
    });
    clone[indexOption].refx3 = null;
    setnewOptions(clone);
  };

  const DupliqueFicheGrue = () => {
    //Recherche des seq sans element choisi
    if (newBOM.some((elt) => elt.piece !== null && elt.piece.refx3 === '')) {
      setAlert('Une ou plusieurs variantes ne sont pas définies', 'danger');
      setFlagSignalVariantes(true);
    } else if (
      !formDataFicheGrue.marque ||
      formDataFicheGrue.marque.length === 0 ||
      !formDataFicheGrue.modele ||
      formDataFicheGrue.modele.length === 0
    ) {
      setAlert('Veuillez renseigner Marque et Modèle', 'danger');
    } else {
      formDataFicheGrue.bom = [...newBOM];
      // formDataFicheGrue.bom = formDataFicheGrue.bom.concat(newPieces);
      formDataFicheGrue.bom = formDataFicheGrue.bom.concat(
        newPieces.filter(
          (elt) => elt.piece !== null && elt.seq !== 0 && elt.visible !== false
        )
      );

      formDataFicheGrue.options = [...newOptions];
      //filtrage des vignettes existantes pour diminuer taille requete
      // for (let elt in formDataFicheGrue.bom) {
      //   elt.piece.vignette = null;
      // }

      if (
        (id === '0' && !modification) ||
        (fichegrue.typemc600.toString() !== typeMC600 &&
          modification === undefined)
      ) {
        formDataFicheGrue.nomenclature_com = dataNomenclatureCom;
      }

      createFichegrue(
        id !== '0' && modification ? id : null,
        formDataFicheGrue,
        navigate,
        false,
        false,
        false
      );
    }
    window.scrollTo(0, 0);
  };

  const AddNewElementBOM = () => {
    setNewPieces((oldArray) => [
      ...oldArray,
      { seq: 0, piece: null, qte: 1, visible: true }
    ]);
  };

  const onSelectionKitBase = (event) => {
    if (listKitBase.length > 0 && listKitBase.length >= event.target.value) {
      setIndexKitBaseSelected(event.target.value);
      // console.log('set index' + event.target.value);
      if (id === '0' && !modification) {
        getFichegrueById(listKitBase[event.target.value].id);
      }
      setDataNomenclatureCom(
        `${listKitBase[event.target.value].serie} - ${
          listKitBase[event.target.value].nomenclature_com
        }`
      );
    }
  };

  const creationDynamicSelectKitbase = (listfg) => {
    let items = [];
    // console.log('IndexKitBaseSelected :' + IndexKitBaseSelected);

    if (IndexKitBaseSelected === -1) {
      items.push(
        <option selected disabled hidden value={0} key={999}>
          Selection nomenclature commerciale
        </option>
      );
    } else {
      items.push(
        <option disabled hidden value={0} key={999}>
          Selection nomenclature commerciale
        </option>
      );
    }
    for (let i = 0; i < listfg.length; i++) {
      if (Number(IndexKitBaseSelected) === Number(i)) {
        items.push(
          <option selected key={i} value={i}>
            {listfg[i].nomenclature_com} - {listfg[i].serie}
          </option>
        );
      } else {
        items.push(
          <option key={i} value={i}>
            {listfg[i].nomenclature_com} - {listfg[i].serie}
          </option>
        );
      }
    }

    return items;
  };

  if (
    loading ||
    loadingpiece ||
    loadingopt ||
    (formDataFicheGrue.marque === '' && id !== '0')
  ) {
    return <Spinner />;
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50 }}>
            {fichegrue === null ||
            flagKitBase === true ||
            fichegrue.modele === 'kitbaseMC603' ||
            (fichegrue.modele === 'kitbaseMC602' &&
              fichegrue.typemc600 !== '' &&
              fichegrue.typemc600 !== null) ? (
              <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                Creation fiche grue MC{typeMC600}
              </h4>
            ) : modification === undefined ? (
              fichegrue.typemc600.toString() !== typeMC600 ? (
                <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                  Conversion depuis MC{fichegrue.typemc600} vers fiche MC
                  {typeMC600}
                </h4>
              ) : (
                <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                  Duplication fiche grue MC{typeMC600}
                </h4>
              )
            ) : (
              <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                Modification fiche grue MC{typeMC600}
              </h4>
            )}

            {id === '0' ||
            (fichegrue.typemc600.toString() !== typeMC600 &&
              modification === undefined) ? (
              // <select class="form-control" onClick={(e) => onSelectionKitBase(e)}>
              <select
                class="form-control"
                onChange={(e) => onSelectionKitBase(e)}
              >
                {creationDynamicSelectKitbase(listKitBase)}
              </select>
            ) : (
              <Fragment></Fragment>
            )}

            <DuplicationFicheGrueEntete
              distincts={distincts}
              fg={formDataFicheGrue}
              onChange={onChangeDataFicheGrue}
            />

            <Fragment>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="card bg-ascorel text-white text-center"
                  style={{ width: '500px' }}
                >
                  <h4 className="card-header  ">Pièces jointes fiche grue :</h4>
                  <ListePiecesJointes
                    OnChangePieceJointe={(list) =>
                      onChangeDataFicheGrue([list], 'piecesjointes')
                    }
                    tailleTexte="normal"
                    listPJ={formDataFicheGrue.piecesjointes}
                  />
                </div>
                <div style={{ margin: 10 }}>
                  <ModalImportPJ
                    addPJ={(list) =>
                      onChangeDataFicheGrue([list], 'piecesjointes')
                    }
                    listPJ={formDataFicheGrue.piecesjointes}
                  />
                </div>
              </div>
            </Fragment>

            <table
              className="table table-striped table-light table-header-group"
              style={{ marginTop: 20 }}
              border="1"
            >
              <thead>
                <tr className="title-td">
                  <td id="th1" width="100" class="text-center">
                    Seq
                  </td>
                  {/* <td className="text-center" id="th1">
                  Type
                </td> */}
                  {id !== '0' && modification === undefined ? (
                    <Fragment>
                      <td className="text-center" id="th2">
                        Fiche actuelle
                      </td>
                      <td className="text-center" id="th3">
                        Nouvelle fiche
                      </td>
                    </Fragment>
                  ) : (
                    <td className="text-center" id="th3">
                      Elements
                    </td>
                  )}

                  <td className="text-center" id="th3" width="100">
                    Qté
                  </td>
                  {/* <td className="text-center" id="th4">
                  {'  '}
                </td> */}
                  <td className="text-center" id="th5">
                    PJ
                  </td>
                  <td className="text-center" id="th6">
                    {'  '}
                  </td>
                </tr>
              </thead>
              <tr>
                <td id="th1" colspan="12">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={AddNewElementBOM}
                  >
                    Ajouter element <IoIosAddCircleOutline />
                  </button>
                </td>
              </tr>

              <tbody>
                <tr className="ssection-td">
                  <td id="th1" colspan="12">
                    Elements ajoutés
                  </td>
                </tr>
                {newPieces.map((elt, index) => (
                  <tr>
                    <ItemAjoutNew
                      duplication={id !== '0' && modification === undefined}
                      listeSequence={listSeq}
                      eltNew={elt}
                      OnChangeQte={(e) =>
                        updateItemNvellePiece('qte', e, index)
                      }
                      listePieces={listPiecesforNew}
                      selectIndex={0}
                      onSelectionList={(refx3) =>
                        updateRefX3ItemNewPieces(refx3, index)
                      }
                      onSelectionSequence={(e) =>
                        updateItemNvellePiece('seq', e, index)
                      }
                      onDeletePiece={(e) => DeleteItemNewPieces(index)}
                      OnChangePieceJointe={(list) =>
                        updatePJItemNewpieces(list, index)
                      }
                    />
                  </tr>
                ))}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colspan="12">
                      CPU/Interface
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq > 110 ? (
                      <div></div>
                    ) : (
                      <tr>
                        <ItemDuplication
                          typeMC600={typeMC600}
                          flagVariante={flagSignalVariantes}
                          duplication={id !== '0' && modification === undefined}
                          eltNew={elt}
                          // eltOld={elt}
                          eltOld={fichegrue.bom[index]}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangePieceJointe={(list) =>
                            updatePiecejointeItemNewBom(list, index)
                          }
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          selectIndex={
                            modification === undefined
                              ? 0
                              : listPiecesParSeq[elt.seq / 10 - 1].findIndex(
                                  (el) =>
                                    el.refx3.includes(
                                      fichegrue.bom[index].piece.refx3
                                    )
                                ) + 1
                          }
                          onSelectionList={(e) =>
                            updateRefX3ItemNewBom(e, index)
                          }
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <div></div>
                )}
                {/* Orientation a partir de seq 120 */}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colspan="12">
                      Orientation
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null ||
                    elt.seq > 170 ||
                    elt.seq < 120 ||
                    elt._id === '111111111111111111111111' ? (
                      <div></div>
                    ) : (
                      <tr>
                        <ItemDuplication
                          typeMC600={typeMC600}
                          flagVariante={flagSignalVariantes}
                          duplication={id !== '0' && modification === undefined}
                          eltNew={elt}
                          // eltOld={elt}
                          eltOld={fichegrue.bom[index]}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangePieceJointe={(list) =>
                            updatePiecejointeItemNewBom(list, index)
                          }
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          selectIndex={
                            modification === undefined
                              ? 0
                              : listPiecesParSeq[elt.seq / 10 - 1].findIndex(
                                  (el) =>
                                    el.refx3.includes(
                                      fichegrue.bom[index].piece.refx3
                                    )
                                ) + 1
                          }
                          onSelectionList={(e) =>
                            updateRefX3ItemNewBom(e, index)
                          }
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <div></div>
                )}
                {/* pour rajout element seq 120 probleme import fiche  */}
                {newBOM.length > 0 &&
                fichegrue !== null &&
                id !== '0' &&
                modification === undefined ? (
                  newBOM.map((elt, index) =>
                    elt._id !== '111111111111111111111111' ? (
                      <div></div>
                    ) : (
                      <tr>
                        <ItemDuplication
                          typeMC600={typeMC600}
                          flagVariante={flagSignalVariantes}
                          duplication={id !== '0' && modification === undefined}
                          eltNew={elt}
                          eltOld={{
                            piece: {
                              nom: 'Ajout automatique sequence manquante'
                            }
                          }}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangePieceJointe={(list) =>
                            updatePiecejointeItemNewBom(list, index)
                          }
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          selectIndex={
                            modification === undefined
                              ? 0
                              : listPiecesParSeq[elt.seq / 10 - 1].findIndex(
                                  (el) =>
                                    el.refx3.includes(
                                      fichegrue.bom[index].piece.refx3
                                    )
                                ) + 1
                          }
                          onSelectionList={(e) =>
                            updateRefX3ItemNewBom(e, index)
                          }
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <div></div>
                )}
                {/* Distribution a partir de seq 180 */}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colspan="12">
                      Distribution
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq < 180 || elt.seq > 220 ? (
                      <div></div>
                    ) : (
                      <tr>
                        <ItemDuplication
                          typeMC600={typeMC600}
                          flagVariante={flagSignalVariantes}
                          duplication={id !== '0' && modification === undefined}
                          eltNew={elt}
                          eltOld={fichegrue.bom[index]}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangePieceJointe={(list) =>
                            updatePiecejointeItemNewBom(list, index)
                          }
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          selectIndex={
                            modification === undefined
                              ? 0
                              : listPiecesParSeq[elt.seq / 10 - 1].findIndex(
                                  (el) =>
                                    el.refx3.includes(
                                      fichegrue.bom[index].piece.refx3
                                    )
                                ) + 1
                          }
                          onSelectionList={(e) =>
                            updateRefX3ItemNewBom(e, index)
                          }
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <div></div>
                )}
                {/* Divers a partir de seq 180 */}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colspan="12">
                      Divers
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq < 230 ? (
                      <div></div>
                    ) : (
                      <tr>
                        <ItemDuplication
                          typeMC600={typeMC600}
                          flagVariante={flagSignalVariantes}
                          duplication={id !== '0' && modification === undefined}
                          eltNew={elt}
                          eltOld={fichegrue.bom[index]}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangePieceJointe={(list) =>
                            updatePiecejointeItemNewBom(list, index)
                          }
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          selectIndex={
                            modification === undefined ||
                            listPiecesParSeq[elt.seq / 10 - 1] === undefined ||
                            fichegrue.bom[index].piece === null
                              ? 0
                              : listPiecesParSeq[elt.seq / 10 - 1].findIndex(
                                  (el) =>
                                    el.refx3.includes(
                                      fichegrue.bom[index].piece.refx3
                                    )
                                ) + 1
                          }
                          onSelectionList={(e) =>
                            updateRefX3ItemNewBom(e, index)
                          }
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <div></div>
                )}

                {/* TEST OPTION */}
                {/* <div style={}> */}
                <tr class="spacer" style={{ height: 40 }}>
                  {' '}
                </tr>
                <tr className="title-td">
                  <td colspan="5">OPTIONS</td>
                </tr>

                {/* </div> */}
                {newOptions.length > 0 ? (
                  newOptions.map((eltOpt, indexOpt) => (
                    <Fragment>
                      {eltOpt.refx3 != null ? (
                        <Fragment>
                          <tr className="ssection-td">
                            <td
                              colspan={
                                id === '0' || modification === 'true'
                                  ? '4'
                                  : '5'
                              }
                            >
                              OPTION - {eltOpt.nom}
                            </td>
                            <td class="text-center">
                              <button
                                className="btn btn-warning"
                                onClick={() => DeleteOptionComplete(indexOpt)}
                              >
                                <MdDelete />
                              </button>{' '}
                            </td>
                          </tr>

                          {eltOpt.bom.length > 0 ? (
                            eltOpt.bom.map((eltBOM, indexBOM) => (
                              <tr class="align-items-center">
                                <ItemDuplication
                                  typeMC600={typeMC600}
                                  flagVariante={flagSignalVariantes}
                                  duplication={
                                    id !== '0' && modification === undefined
                                  }
                                  // duplication={fichegrue.options.length > 0}
                                  // dans le cas ou la fichegrue na pas d'option , on reste quand même en duplication =  true pour avoir le bon nombre de colonne, mais on
                                  // envoie un element vide qui affichera une string ""
                                  eltNew={eltBOM}
                                  // fichegrue.options[indexOpt].bom[indexBOM].piece si on duplique une fiche pour laquelle on avait supprimé des options
                                  eltOld={
                                    fichegrue !== null &&
                                    fichegrue.options.length > 0 &&
                                    fichegrue.typemc600 !== '' &&
                                    fichegrue.typemc600 !== null &&
                                    fichegrue.typemc600.toString() ===
                                      typeMC600 &&
                                    fichegrue.options.find(
                                      (elt) => elt.refx3 === eltOpt.refx3
                                    ) &&
                                    fichegrue.options.find(
                                      (elt) => elt.refx3 === eltOpt.refx3
                                    ).bom[0] != null &&
                                    fichegrue.options.find(
                                      (elt) => elt.refx3 === eltOpt.refx3
                                    ).bom[0].piece != null
                                      ? fichegrue.options
                                          .find(
                                            (elt) => elt.refx3 === eltOpt.refx3
                                          )
                                          .bom.find(
                                            (elti) => elti.seq === eltBOM.seq
                                          )
                                      : {
                                          qte: 1,
                                          seq: 1,
                                          piece: {
                                            nom: 'element non present',
                                            refx3: 'XXXXXXXX',
                                            commentaire: '',
                                            seqpossible: null,
                                            typemc600: null
                                          }
                                        }
                                  }
                                  OnChangeQte={(e) =>
                                    updateItemNewOptions(
                                      'qte',
                                      e,
                                      indexOpt,
                                      indexBOM
                                    )
                                  }
                                  listePieces={
                                    listPiecesParSeq[eltBOM.seq / 10 - 1]
                                  }
                                  selectIndex={
                                    fichegrue !== null &&
                                    fichegrue.options.length > 0 &&
                                    fichegrue.typemc600 !== '' &&
                                    fichegrue.typemc600 !== null &&
                                    fichegrue.typemc600.toString() ===
                                      typeMC600 &&
                                    fichegrue.options.find(
                                      (elt) => elt.refx3 === eltOpt.refx3
                                    ) &&
                                    fichegrue.options.find(
                                      (elt) => elt.refx3 === eltOpt.refx3
                                    ).bom[indexBOM] != null &&
                                    fichegrue.options.find(
                                      (elt) => elt.refx3 === eltOpt.refx3
                                    ).bom[indexBOM].piece != null &&
                                    modification === 'true'
                                      ? listPiecesParSeq[
                                          eltBOM.seq / 10 - 1
                                        ].findIndex((el) =>
                                          el.refx3.includes(
                                            fichegrue.options
                                              .find(
                                                (elt) =>
                                                  elt.refx3 === eltOpt.refx3
                                              )
                                              .bom.find(
                                                (elti) =>
                                                  elti.refx3 === eltBOM.refx3
                                              ).refx3
                                          )
                                        ) + 1
                                      : 0
                                  }
                                  onSelectionList={(e) =>
                                    updateRefX3ItemNewOptions(
                                      e,
                                      indexOpt,
                                      indexBOM
                                    )
                                  }
                                  OnChangePieceJointe={(list) =>
                                    updatePJItemNewOptions(
                                      list,
                                      indexOpt,
                                      indexBOM
                                    )
                                  }
                                  onDeletePiece={(e) =>
                                    DeleteItemNewOptions(indexOpt, indexBOM)
                                  }
                                />
                              </tr>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </Fragment>
                      ) : (
                        <div></div>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <div></div>
                )}
              </tbody>
            </table>

            <div style={{ marginTop: 50, marginBottom: 50 }}>
              <label for="comment">Ajouter un commentaire:</label>
              <textarea
                className="form-control"
                rows="4"
                placeholder="Renseigner vos commentaires ici"
                type="text"
                name="commentaire"
                value={formDataFicheGrue.commentaire}
                // onChange={(e) => onChangeDataFicheGrue(e)}
                onChange={(e) =>
                  onChangeDataFicheGrue([e.target.value], 'commentaire')
                }
              ></textarea>
            </div>

            <div style={{ marginBottom: 50 }} className="row">
              <div className="col text-center">
                {/* <Link to={`/fichegrue/${id}`}> */}
                <button
                  className="btn btn-secondary btn-lg center-block"
                  onClick={() => navigate(-1)}
                >
                  Annuler <TiArrowBackOutline />
                </button>
                {/* </Link> */}
              </div>

              <div className="col text-center">
                {/* <Link to={`/fichegrue/${id}`}> */}
                <button
                  className="btn btn-success btn-lg center-block"
                  onClick={DupliqueFicheGrue}
                >
                  Enregistrer fiche grue <FaSave />
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </Fragment>
      </section>
    );
  }
};

DuplicationFicheGrue.propTypes = {
  getFichegrueByCustomParameters: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  fichegrue: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  optionfichegrue: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getDistinctRefreshAll: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  piece: state.piece,
  auth: state.auth,
  optionfichegrue: state.optionfichegrue
});

export default connect(mapStateToProps, {
  getFichegrueById,
  createFichegrue,
  getPiecesBySeq,
  getFichegrueByCustomParameters,
  getOptions,
  setAlert,
  getDistinctRefreshAll
})(DuplicationFicheGrue);
