import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import { useState } from 'react';
import ModalReponseAdmin from './ModalReponseAdmin';

export function BandeauDemandes({
  isAdmin,
  demandeModification,
  annulerDemande,
  demandeCompletee,
  marquerVue
}) {
  const [show, setShow] = useState(false);
  const [statusChoisi, setStatusChoisi] = useState('');
  const [textRep, setTextRep] = useState('');
  const openModalAdmin = () => setShow(true);
  const closeModalAdmin = () => setShow(false);

  const fonctRetour = () => {
    demandeCompletee(demandeModification, statusChoisi, textRep);
  };

  // Cette fonction vérifie si le statut des demandes de modification n'est ni "Validee-vue" ni "Refusee-vue". Si le statut n'est ni "Validee-vue" ni "Refusee-vue", elle retourne vrai ; sinon, elle retourne faux.
  const StatusVue = () => {
    if (
      demandeModification.status !== 'Validee-vue' &&
      demandeModification.status !== 'Refusee-vue'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {isAdmin ? (
        // Affichage pour l'admin
        <>
          {demandeModification.status.includes('Cree') ? (
            <>
              <h6 className="p-3 mb-2 bg-ascorel text-white">
                <p>
                  Status:
                  {demandeModification.status.includes('Validee')
                    ? 'Validee'
                    : demandeModification.status.includes('Refusee')
                    ? 'Refusee'
                    : 'Cree'}
                </p>
                <p style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  La demande: {demandeModification.texte}
                </p>
                <p> De : {demandeModification.auteur.name}</p>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    style={{ marginTop: 20 }}
                    className="btn btn-success float-left"
                    onClick={() => {
                      openModalAdmin();
                      setStatusChoisi('Validee');
                    }}
                  >
                    Valider
                  </Button>
                  <Button
                    style={{ marginTop: 20 }}
                    className="btn btn-danger float-left"
                    onClick={() => {
                      openModalAdmin();
                      setStatusChoisi('Refusee');
                    }}
                  >
                    Refuser
                  </Button>
                  {show && (
                    <ModalReponseAdmin
                      show={show}
                      closeModalAdmin={closeModalAdmin}
                      reponseDemande={setTextRep}
                      demandeCompletee={fonctRetour}
                    />
                  )}
                </div>
              </h6>
            </>
          ) : null}
        </>
      ) : (
        // Affichage pour l'utilisateur
        <>
          {StatusVue ? (
            <>
              <h6 className="p-3 mb-2 bg-ascorel text-white">
                <p>
                  Status:
                  {demandeModification.status.includes('Validee')
                    ? 'Validee'
                    : demandeModification.status.includes('Refusee')
                    ? 'Refusee'
                    : 'Cree'}
                </p>
                <p style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  Votre demande est : {demandeModification.texte}
                </p>
                {demandeModification.reponse !== '' &&
                demandeModification.reponse !== undefined ? (
                  <p style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                    La réponse de l'admin est : {demandeModification.reponse}
                  </p>
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  {/* Si le statut des demandes de modification n'est pas "Cree", alors on affiche le bouton "Marquer comme vue". Sinon elle affiche le bouton "Annuler la demande". */}
                  {!demandeModification.status.includes('Cree') ? (
                    <Button
                      style={{ marginTop: 20 }}
                      className="btn btn-success float-left"
                      onClick={marquerVue}
                    >
                      Marquer comme vue
                    </Button>
                  ) : (
                    <Button
                      style={{ marginTop: 20 }}
                      className="btn btn-danger float-left"
                      onClick={annulerDemande}
                    >
                      Annuler la demande
                    </Button>
                  )}
                </div>
              </h6>
            </>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
}
