import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getFichegrueById,
  createFichegrue,
  deleteFichegrue
} from '../../actions/fichegrue';
import { getPiecesBySeq } from '../../actions/piece';
import Spinner from '../layout/Spinner';
import ElementNomenclature from '../nomenclaturebase/ElementNomenclature';
import { FaSave } from 'react-icons/fa';
import { TiArrowBackOutline } from 'react-icons/ti';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';

const CreateModifNomenclatureBase = ({
  getFichegrueById,
  createFichegrue,
  deleteFichegrue,
  getPiecesBySeq,
  fichegrue: { fichegrue, fichesgrues, loading, update },
  piece: { pieces, loadingpiece },
  auth
}) => {
  const { id, typeMC600 } = useParams();
  const navigate = useNavigate();

  const [listSeq, setListSeq] = useState([]);
  const [newBOM, setNewBom] = useState([]);
  const [newPieces, setNewPieces] = useState([]);
  // const [listPiecesforNew, setListPiecesforNew] = useState([]);
  const [listPiecesParSeq, setListPiecesParSeq] = useState([]);
  const [listPiecesExclues, setListPiecesExclues] = useState([]);

  const [formDataFicheGrue, setFormDataFicheGrue] = useState({
    marque: '',
    modele: '',
    serie: '',
    type1: '',
    origine: '',
    piecesjointes: [],
    commentaire: '',
    archive: '',
    liaison: '',
    serialnumber: '',
    anneeprod: '',
    infovue: '',
    typemc600: '',
    index: '',
    nbs: '',
    module: '',
    effacable: true,
    nomenclature_com: ''
  });

  useEffect(() => {
    if (id !== '0') {
      getFichegrueById(id);
    }
    getPiecesBySeq(typeMC600 === '602' ? '602' : '603');
  }, [getFichegrueById, id, getPiecesBySeq]);

  // remplissage liste sequences
  useEffect(() => {
    if (loading === false) {
      if (fichegrue !== null) {
        const typemc = typeMC600 === '602' ? 602 : 603;

        const clone = fichegrue.bom.map((i) => ({ ...i }));
        clone.sort((a, b) => a.seq - b.seq);
        setNewBom(clone);

        const clone1 = fichegrue.pieces_exclues_nomcom_v2.map((i) => ({
          ...i
        }));
        clone1.sort((a, b) => a.seq - b.seq);
        setListPiecesExclues(clone1);

        setFormDataFicheGrue({
          ...fichegrue,
          bom: null,
          effacable: true,
          typemc600: typemc
        });
      }
    } else {
      //reset de la page sur loading  = true
      setListSeq([]);
      setNewBom([]);
      setNewPieces([]);
      setFormDataFicheGrue({
        marque: 'kitbaseMC603',
        modele: '',
        serie: '',
        type1: '',
        origine: '',
        piecesjointes: [],
        commentaire: '',
        archive: '',
        liaison: '',
        serialnumber: '',
        anneeprod: '',
        infovue: '',
        typemc600: 603,
        index: '',
        nbs: '',
        module: '',
        effacable: true
      });
    }
  }, [loading, fichegrue, fichesgrues]);

  // remplissage des listes pieces by Seq et list sequences
  useEffect(() => {
    if (pieces.length !== 0 && (fichegrue !== null || id === '0')) {
      let items = [];
      for (let i = 1; i <= pieces.length; i++) {
        items.push(i * 10);
      }
      setListSeq(items);

      const clone = [];
      pieces.forEach((elt, index) => {
        const clone2 = [];
        elt.forEach((piece) => {
          clone2.push({
            ...piece,
            flaggedForRemoval: listPiecesExclues.some(
              (e) => e.piece._id === piece._id && e.seq === (index + 1) * 10
            )
          });
        });
        clone.push(clone2);
      });
      setListPiecesParSeq(clone);
    }
  }, [pieces, fichegrue]);

  useEffect(() => {
    const navigateTo = () => navigate(`/fichegrue/${fichegrue._id}`);
    if (fichegrue !== null && update === true) {
      navigateTo();
    }
  }, [update, fichegrue]);

  const onChangeDataFicheGrue = (e) => {
    setFormDataFicheGrue({
      ...formDataFicheGrue,
      [e.target.name]: e.target.value
    });
  };

  const updateItemNewBom = (prop, event, index) => {
    const old = newBOM[index];
    const updated = { ...old, [prop]: parseInt(event.target.value, 10) };
    const clone = [...newBOM];
    clone[index] = updated;
    setNewBom(clone);
  };

  const updateItemNvellePiece = (prop, event, index) => {
    const old = newPieces[index];
    const updated = { ...old, [prop]: parseInt(event.target.value, 10) };
    const clone = [...newPieces];
    clone[index] = updated;
    setNewPieces(clone);
  };

  const DeleteItemNewBom = (index) => {
    const old = newBOM[index];
    const updated = { ...old, piece: null };
    const clone = [...newBOM];
    clone[index] = updated;
    setNewBom(clone);
  };

  //on efface pas la piece, on la passe en invisible pour eviter de devoir rafraichir tt les composants ItemAjoutNew
  // piece =  null garantie la non-creation à la fin
  const DeleteItemNewPieces = (index) => {
    const old = newPieces[index];
    const updated = { ...old, piece: null, seq: null, visible: false };
    const clone = [...newPieces];
    clone[index] = updated;
    setNewPieces(clone);
  };

  const RemovePieceFromListPieces = (indexSeq, indexPiece, removed) => {
    console.log('RemovePieceFromSeqNewBom' + indexSeq + ' ' + indexPiece);
    const oldListPieceSeq = listPiecesParSeq[indexSeq];
    const oldPiece = oldListPieceSeq[indexPiece];
    const updated = { ...oldPiece, flaggedForRemoval: removed };
    const clone = [...listPiecesParSeq];
    clone[indexSeq][indexPiece] = updated;
    setListPiecesParSeq(clone);
    if (removed) {
      const clone = [...listPiecesExclues];
      clone.push({ piece: oldPiece, seq: (indexSeq + 1) * 10 });
      setListPiecesExclues(clone);
    } else if (listPiecesExclues.length > 0) {
      let clone = listPiecesExclues.filter(
        (i) => !(i.piece._id === oldPiece._id && i.seq === (indexSeq + 1) * 10)
      );
      setListPiecesExclues([...clone]);
    }
  };

  const AddNewElementBOM = () => {
    setNewPieces((oldArray) => [
      ...oldArray,
      { seq: 0, piece: null, qte: 1, visible: true }
    ]);
  };

  const DupliqueFicheGrue = () => {
    formDataFicheGrue.bom = [...newBOM.filter((elt) => elt.piece !== null)];

    //ajout des pieces pour les nouvelles sequence, on prend n'importe laquelle
    const newPiecesPopulated = [
      ...newPieces.filter((elt) => elt.seq !== null && elt.visible !== false)
    ];
    for (let i = 0; i < newPiecesPopulated.length; i++) {
      const old = newPiecesPopulated[i];
      const updated = { ...old, piece: listPiecesParSeq[old.seq / 10 - 1][0] };
      newPiecesPopulated[i] = updated;
    }

    formDataFicheGrue.bom = formDataFicheGrue.bom.concat(
      newPiecesPopulated.filter(
        (elt) => elt.piece !== null && elt.seq !== 0 && elt.visible !== false
      )
    );
    formDataFicheGrue.modele = formDataFicheGrue.marque;
    formDataFicheGrue.typemc600 =
      formDataFicheGrue.modele === 'kitbaseMC603' ? 603 : 602;

    //check si les sequences des pieces exclus sont presentes dans la bom final
    const listSeqBom = formDataFicheGrue.bom
      .filter((elt) => elt.piece !== null && elt.seq !== 0)
      .map((piece) => piece.seq);
    formDataFicheGrue.pieces_exclues_nomcom_v2 = [
      ...listPiecesExclues.filter(
        (elt) =>
          listSeqBom.includes(elt.seq) ||
          (elt.seqpossible && listSeqBom.includes(elt.seqpossible[0]))
      )
    ];

    createFichegrue(
      id !== '0' ? id : null,
      formDataFicheGrue,
      navigate,
      false,
      true,
      false
    );

    window.scrollTo(0, 0);
  };

  const EffacementFicheGrue = () => {
    if (
      window.confirm(
        'Etes vous sur ? Cette opération ne peut pas être annulée !'
      )
    ) {
      deleteFichegrue(fichegrue._id);
      navigate(-1);
    }
  };

  // si pieces.length < 40, axios a tronqué le resultat de la requete
  if (loading || loadingpiece || listPiecesParSeq.length < 40) {
    return <Spinner />;
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50 }}>
            {id === '0' ? (
              <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                Creation Nomenclature commerciale
              </h4>
            ) : (
              <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                Modification Nomenclature commerciale {typeMC600}
              </h4>
            )}
            {/* nom et reference x3 */}
            <form className="form">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="*Nom nomenclature"
                  name="nomenclature_com"
                  value={formDataFicheGrue.nomenclature_com || ''}
                  onChange={(e) => onChangeDataFicheGrue(e)}
                />
                <small className="form-text">
                  Nom usuel de la nomenclature commerciale
                </small>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="*réference X3"
                  name="serie"
                  value={formDataFicheGrue.serie}
                  onChange={(e) => onChangeDataFicheGrue(e)}
                />
                <small className="form-text">
                  Réference X3 de la nomenclature commerciale
                </small>
              </div>
              <div className="form-group">
                <select
                  name="marque"
                  value={formDataFicheGrue.marque}
                  onChange={(e) => onChangeDataFicheGrue(e)}
                >
                  <option value="">* Selectionner systeme</option>
                  <option value="kitbaseMC602">MC 602</option>
                  <option value="kitbaseMC603">MC 603</option>
                </select>
                <small className="form-text">Type système</small>
              </div>
            </form>

            <table
              className="table table-striped table-light table-header-group"
              style={{ marginTop: 20 }}
              border="1"
            >
              <thead>
                <tr className="title-td">
                  <td id="th1" width="100" className="text-center">
                    Seq
                  </td>
                  <td className="text-center">Elements</td>
                  <td className="text-center" width="100">
                    Qté
                  </td>
                  <td className="text-center" width="80"></td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td id="th1" colSpan="12">
                    <button
                      className="btn btn-secondary"
                      onClick={AddNewElementBOM}
                    >
                      Ajouter element <IoIosAddCircleOutline />
                    </button>
                  </td>
                </tr>

                <tr className="ssection-td">
                  <td id="th1" colSpan="12">
                    Elements ajoutés
                  </td>
                </tr>
                {newPieces.map((elt, index) =>
                  elt.visible === false ? (
                    <Fragment key={index}></Fragment>
                  ) : (
                    <tr>
                      <ElementNomenclature
                        sequence={elt.seq}
                        qte={elt.qte}
                        listePieces={
                          elt.seq !== 0
                            ? listPiecesParSeq[elt.seq / 10 - 1]
                            : []
                        }
                        onRemoveVariante={RemovePieceFromListPieces}
                        onDeletePiece={(e) => DeleteItemNewPieces(index)}
                        listSequences={listSeq}
                        OnChangeQte={(e) =>
                          updateItemNvellePiece('qte', e, index)
                        }
                        OnChangeSeq={(e) =>
                          updateItemNvellePiece('seq', e, index)
                        }
                      />
                    </tr>
                  )
                )}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colSpan="12">
                      CPU/Interface
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq > 110 ? (
                      <Fragment key={index}></Fragment>
                    ) : (
                      <tr key={elt.piece._id}>
                        <ElementNomenclature
                          sequence={elt.seq}
                          qte={elt.qte}
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          onRemoveVariante={RemovePieceFromListPieces}
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                          listSequences={listSeq}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangeSeq={(e) => updateItemNewBom('seq', e, index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <Fragment></Fragment>
                )}
                {/* Orientation a partir de seq 120 */}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colSpan="12">
                      Orientation
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq > 170 || elt.seq < 120 ? (
                      <Fragment key={index}></Fragment>
                    ) : (
                      <tr key={elt.piece._id}>
                        <ElementNomenclature
                          sequence={elt.seq}
                          qte={elt.qte}
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          onRemoveVariante={RemovePieceFromListPieces}
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                          listSequences={listSeq}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangeSeq={(e) => updateItemNewBom('seq', e, index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <Fragment></Fragment>
                )}
                {/* Distribution a partir de seq 180 */}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colSpan="12">
                      Distribution
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq < 180 || elt.seq > 220 ? (
                      <Fragment key={index}></Fragment>
                    ) : (
                      <tr key={elt.piece._id}>
                        <ElementNomenclature
                          sequence={elt.seq}
                          qte={elt.qte}
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          onRemoveVariante={RemovePieceFromListPieces}
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                          listSequences={listSeq}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangeSeq={(e) => updateItemNewBom('seq', e, index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <Fragment></Fragment>
                )}
                {/* Divers a partir de seq 180 */}
                {fichegrue !== null ? (
                  <tr className="ssection-td">
                    <td id="th1" colSpan="12">
                      Divers
                    </td>
                  </tr>
                ) : (
                  <div></div>
                )}
                {newBOM.length > 0 && fichegrue !== null ? (
                  newBOM.map((elt, index) =>
                    elt.piece === null || elt.seq < 230 ? (
                      <Fragment key={index}></Fragment>
                    ) : (
                      <tr key={elt.piece._id}>
                        <ElementNomenclature
                          sequence={elt.seq}
                          qte={elt.qte}
                          listePieces={listPiecesParSeq[elt.seq / 10 - 1]}
                          onRemoveVariante={RemovePieceFromListPieces}
                          onDeletePiece={(e) => DeleteItemNewBom(index)}
                          listSequences={listSeq}
                          OnChangeQte={(e) => updateItemNewBom('qte', e, index)}
                          OnChangeSeq={(e) => updateItemNewBom('seq', e, index)}
                        />
                      </tr>
                    )
                  )
                ) : (
                  <Fragment></Fragment>
                )}
              </tbody>
            </table>

            <div style={{ marginBottom: 50 }} className="row">
              <div className="col text-center">
                <button
                  className="btn btn-secondary btn-lg center-block"
                  onClick={() => navigate(-1)}
                >
                  Annuler <TiArrowBackOutline />
                </button>
              </div>

              <div className="col-3 text-center">
                <button
                  className="btn btn-warning btn-lg float-left"
                  onClick={EffacementFicheGrue}
                >
                  Supprimer nomenclature <MdDelete />
                </button>
              </div>

              <div className="col text-center">
                <button
                  className="btn btn-success btn-lg center-block"
                  onClick={DupliqueFicheGrue}
                >
                  Enregistrer nomenclature <FaSave />
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      </section>
    );
  }
};

CreateModifNomenclatureBase.propTypes = {
  auth: PropTypes.object.isRequired,
  fichegrue: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  getFichegrueById: PropTypes.func.isRequired,
  createFichegrue: PropTypes.func.isRequired,
  deleteFichegrue: PropTypes.func.isRequired,
  getPiecesBySeq: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  piece: state.piece,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getFichegrueById,
  createFichegrue,
  deleteFichegrue,
  getPiecesBySeq
})(CreateModifNomenclatureBase);
