import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getAllNomenclatureBase } from '../../actions/fichegrue';
import ItemNomenclatureBase from '../nomenclaturebase/ItemNomenclatureBase';
import { MdPlaylistAdd } from 'react-icons/md';

const ListNomenclatureBase = ({
  getAllNomenclatureBase,
  fichegrue: { fichesgrues, loading }
}) => {
  useEffect(() => {
    getAllNomenclatureBase();
  }, [getAllNomenclatureBase]);

  const [nbFiche602, setnbFiche602] = useState(0);
  const [nbFiche603, setnbFiche603] = useState(0);
  const [displayFiche603, setdisplayFiche603] = useState(true);
  const [displayFiche602, setdisplayFiche602] = useState(true);

  useEffect(() => {
    let compteur602 = 0;
    let compteur603 = 0;
    if (fichesgrues.length > 0) {
      fichesgrues.map((fg, index) =>
        fg.typemc600 === 602
          ? (compteur602 = compteur602 + 1)
          : (compteur603 = compteur603 + 1)
      );
      setnbFiche602(compteur602);
      setnbFiche603(compteur603);
    }
  }, [fichesgrues]);

  if (loading) {
    return <Spinner />;
  } else if (fichesgrues === null || fichesgrues.length === 0) {
    return (
      <div style={{ marginTop: 100, marginBottom: 50 }}>
        <h3 className="text-center">aucune nomenclature commerciale trouvée</h3>
      </div>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Liste nomenclatures commerciale :
            </h4>
            <div
              className="col text-center"
              style={{ marginBottom: 40, marginTop: 40 }}
            >
              <button
                type="button"
                class={`btn ${
                  displayFiche602 === true ? 'btn-warning ' : 'btn-secondary '
                } text-center`}
                style={{ marginRight: 50 }}
                onClick={() => setdisplayFiche602(!displayFiche602)}
              >
                Nomenclatures MC602{' '}
                <span class="badge badge-light">{nbFiche602}</span>
              </button>
              <button
                type="button"
                class={`btn ${
                  displayFiche603 === true ? 'btn-success ' : 'btn-secondary '
                } text-center`}
                style={{ marginLeft: 50 }}
                onClick={() => setdisplayFiche603(!displayFiche603)}
              >
                Nomenclatures MC603{' '}
                <span class="badge badge-light">{nbFiche603}</span>
              </button>
            </div>
            <div className="parent-flex">
              <div className="child-left-auto">
                <Link to="/nomenclaturebase/603/0" className="text-navbar">
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setdisplayFiche602(!displayFiche602)}
                      style={{ marginRight: '0rem', marginBottom: '1rem' }}
                    >
                      Creer nomenclature <MdPlaylistAdd />
                    </button>
                  </div>
                </Link>
              </div>
            </div>

            <table
              className="table text-nowrap table-striped table-light table-header-group table-container"
              border="1"
            >
              <thead>
                <tr className="sticky">
                  <th className="text-center" id="th1"></th>
                  <th className="text-center" id="th76"></th>
                  <th className="text-center" id="th3" width="100">
                    Derniere modification
                  </th>
                  <th className="text-center " id="th5">
                    Nom
                  </th>
                  <th className="text-center" id="th6">
                    Reference
                  </th>
                </tr>
              </thead>
              <tbody>
                {fichesgrues.map((fichegrue, index) =>
                  fichegrue.modele !== 'kitbaseMC603' &&
                  fichegrue.modele !== 'kitbaseMC602' &&
                  fichegrue.origine !== 'internal' ? (
                    <div>?</div>
                  ) : (
                    <ItemNomenclatureBase
                      displayFiche602={displayFiche602}
                      displayFiche603={displayFiche603}
                      key={fichegrue._id}
                      fichegrue={fichegrue}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        </Fragment>
      </section>
    );
  }
};

ListNomenclatureBase.propTypes = {
  getAllNomenclatureBase: PropTypes.func.isRequired,
  fichegrue: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getAllNomenclatureBase
})(ListNomenclatureBase);
