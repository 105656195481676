import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';

const editStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'white'
  }
};
export default function ModalDemandeModification({
  show,
  closeModalUser,
  nouvelleDemande
}) {
  const [valueEdit, setValueEdit] = useState('');
  function handleTextarea(event) {
    setValueEdit(event.target.value);
  }
  function handleSubmit(e) {
    e.preventDefault();
    closeModalUser();
    //On appelle nouvelleDemande la fonction qui permet d'appeler la fonction créant une demande de modification.
    nouvelleDemande(valueEdit);
    // Permet de remonter en haut de l'écran
    window.scrollTo(0, 0);
  }
  return (
    <React.Fragment>
      <Modal
        show={show}
        closeModalUser={closeModalUser}
        style={editStyles}
        contentLabel="ModalDemandeModification"
      >
        <div>
          <Modal.Header style={{ display: 'flex', justifyContent: 'center' }}>
            <Modal.Title
              style={{ color: 'var(--ascorel-color)', fontSize: '35px' }}
            >
              Demande de modification :
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <textarea
                id="editFiche"
                rows="8"
                placeholder="Veuillez décrire en détail les modifications souhaitées"
                style={{ padding: '3px' }}
                onChange={handleTextarea}
                required
              ></textarea>
              <div
                className="float-right"
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="primary"
                  className="btn btn-primary btn-lg"
                  type="submit"
                  style={{
                    marginTop: 20,
                    marginBottom: 50
                  }}
                >
                  Envoyer
                </Button>
                <Button
                  variant="secondary"
                  className="btn btn-lg"
                  type="submit"
                  style={{ marginTop: 20, marginBottom: 50 }}
                  onClick={closeModalUser}
                >
                  Annuler
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
}
