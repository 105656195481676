import { Fragment, useEffect, useState } from 'react';
import React from 'react';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllDemandesFicheGrue } from '../../actions/fichegrue';
import { Link } from 'react-router-dom';
import { MdInfoOutline } from 'react-icons/md';
import Moment from 'react-moment';
import moment from 'moment';

const ListeDemandesModification = ({
  getAllDemandesFicheGrue,
  fichegrue: { fichesgrues, loading }
}) => {
  useEffect(() => {
    getAllDemandesFicheGrue();
  }, [getAllDemandesFicheGrue]);
  const [listeDemandes, setListeDemandes] = useState([]);
  const [displayEtatValidee, setDisplayEtatValidee] = useState(false);
  const [displayEtatRefusee, setDisplayEtatRefusee] = useState(false);
  const [displayEtatEnAttente, setDisplayEtatEnAttente] = useState(true);

  useEffect(() => {
    if (loading === false && fichesgrues !== null) {
      const demandes = fichesgrues.reduce((acc, elt) => {
        if (elt.demandes_modification !== null) {
          elt.demandes_modification.forEach((modif) =>
            acc.push({
              marque: elt.marque,
              modele: elt.modele,
              id: elt._id,
              ...modif
            })
          );
        }
        return acc;
      }, []);
      // console.log('ici demandes: ' + JSON.stringify(demandes));
      const demandeStatus = [];
      demandes.forEach((element) => {
        if (element.status !== 'Vue-UserDelete') {
          demandeStatus.push(element);
        }
      });
      setListeDemandes(demandeStatus);
    } else {
      setListeDemandes([]);
    }
  }, [loading, fichesgrues]);

  if (loading) {
    return <Spinner />;
  } else if (
    fichesgrues === null ||
    fichesgrues.length === 0 ||
    listeDemandes.length === 0
  ) {
    return (
      <div style={{ marginTop: 100, marginBottom: 50 }}>
        <h3 className="text-center">Aucune demandes de modification trouvée</h3>
      </div>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Liste des demandes de modification des fiches grue :
            </h4>
            <div
              className="col text-center"
              style={{ marginBottom: 40, marginTop: 40 }}
            >
              <button
                type="button"
                className={`btn text-center ${
                  displayEtatEnAttente === true
                    ? 'btn-warning'
                    : 'btn-secondary '
                }`}
                style={{ marginRight: 50 }}
                onClick={() => setDisplayEtatEnAttente(!displayEtatEnAttente)}
              >
                Demandes en attente{' '}
              </button>
              <button
                type="button"
                className={`btn text-center ${
                  displayEtatValidee === true ? 'btn-success' : 'btn-secondary '
                }`}
                style={{ marginLeft: 50, marginRight: 50 }}
                onClick={() => setDisplayEtatValidee(!displayEtatValidee)}
              >
                Demandes validées{' '}
              </button>
              <button
                type="button"
                className={`btn text-center ${
                  displayEtatRefusee === true ? 'btn-danger' : 'btn-secondary '
                }`}
                style={{ marginLeft: 50 }}
                onClick={() => setDisplayEtatRefusee(!displayEtatRefusee)}
              >
                Demandes refusées{' '}
              </button>
            </div>
            <table
              className="table text-nowrap table-striped table-light table-header-group table-container"
              style={{ marginTop: 20 }}
              border="1"
            >
              <thead>
                <tr className="sticky">
                  <th className="text-center">Etat</th>
                  <th className="text-center ">Marque</th>
                  <th className="text-center ">Modele</th>
                  <th className="text-center">Detail</th>
                  <th className="text-center">Auteur</th>
                  <th className="text-center">Date modification</th>
                </tr>
              </thead>
              <tbody>
                {listeDemandes.map((modification, index) =>
                  // Si l'état du bouton 'Demandes Validees' est vrai et que le statut des demandes de modifications est 'validee' ou 'validee-vue'
                  (displayEtatValidee === true &&
                    modification.status.toLowerCase().startsWith('validee')) ||
                  // Ou si l'état du bouton 'Demandes Refusees' est vrai et que le statut des demandes de modifications est 'refusee' ou 'refusee-vue'
                  (displayEtatRefusee === true &&
                    modification.status.toLowerCase().startsWith('refusee')) ||
                  // Ou si l'état du bouton 'Demandes en attente' est vrai et que le statut des demandes de modifications est 'cree', alors on affiche le corps du tableau.
                  (displayEtatEnAttente === true &&
                    modification.status.toLowerCase().startsWith('cree')) ? (
                    <Fragment key={index}>
                      <tr>
                        <td className='width="50" align-middle text-center'>
                          {modification.status
                            .toLowerCase()
                            .startsWith('validee') ? (
                            <h4>
                              <span className="badge badge-pill badge-success p-2 mb-2 ">
                                Validee
                              </span>
                            </h4>
                          ) : modification.status
                              .toLowerCase()
                              .startsWith('refusee') ? (
                            <h4>
                              <span className="badge badge-pill badge-danger p-2 mb-2">
                                Refusee
                              </span>
                            </h4>
                          ) : (
                            <h4>
                              <span className="badge badge-pill badge-warning p-2 mb-2">
                                En attente
                              </span>
                            </h4>
                          )}
                        </td>
                        <td className="text-center align-middle">
                          {modification.marque}
                        </td>
                        <td className="text-center align-middle">
                          {modification.modele}
                        </td>
                        <td className="text-center align-middle">
                          {/* Si le texte de la demande de modification a plus de 40 caractères, alors couper le texte au 40ème caractère et ajouter '...' , sinon afficher le texte entier.*/}
                          {modification.texte.length > 40
                            ? modification.texte.substring(0, 40) + '...'
                            : modification.texte}
                          <Link to={`/fichegrue/${modification.id}`}>
                            <button
                              className="btn btn-ascorel btn-lg"
                              style={{ float: 'right' }}
                            >
                              {modification.status.includes('Cree')
                                ? 'En savoir +'
                                : 'Voir fiche'}
                              <MdInfoOutline color="white" />
                            </button>
                          </Link>
                        </td>
                        <td className="text-center align-middle">
                          {modification.auteur &&
                          modification.auteur.name !== null
                            ? modification.auteur.name
                            : 'Utilisateur supprimé'}
                        </td>
                        <td className="text-center align-middle">
                          <Moment format="DD/MM/YYYY HH:mm">
                            {moment.utc(modification.date).local()}
                          </Moment>
                        </td>
                      </tr>
                    </Fragment>
                  ) : (
                    <tr key={index}></tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </Fragment>
      </section>
    );
  }
};

ListeDemandesModification.propTypes = {
  getAllDemandesFicheGrue: PropTypes.func.isRequired,
  fichegrue: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getAllDemandesFicheGrue
})(ListeDemandesModification);
