import React, { Fragment, useEffect, useState } from 'react';
import { BandeauDemandes } from '../demandeModifFG/BandeauDemandes';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFichegrue } from '../../actions/fichegrue';

const InteractionDemandesModification = ({
  createFichegrue,
  fichegrue: { fichegrue, loading },
  auth
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const initialState = {
    demandes_modification: []
  };

  const [copieLocalDemandesModification, setCopieLocalDemandesModification] =
    useState(initialState);

  const retourAdminDemande = (
    demandeActuelle,
    valeurStatus,
    reponseAdminDemande
  ) => {
    const listeRetourAdmin = copieLocalDemandesModification.map((element) => {
      if (element === demandeActuelle) {
        return {
          status: valeurStatus,
          reponse: reponseAdminDemande,
          texte: element.texte,
          date: element.date,
          auteur: element.auteur
        };
      }
      return element;
    });
    const formDataAEnvoyer = {
      demandes_modification: listeRetourAdmin
    };
    createFichegrue(id, formDataAEnvoyer, navigate, false, false, false);
  };

  const AnnulerDemandeModification = (demande) => {
    const demandeAnnuler = copieLocalDemandesModification.filter(
      (annulerDemande) => annulerDemande !== demande
    );
    setCopieLocalDemandesModification(demandeAnnuler);
    const formDataAEnvoyer = {
      demandes_modification: demandeAnnuler
    };
    createFichegrue(id, formDataAEnvoyer, navigate, false, false, true);
  };

  const DemandeVue = (demande) => {
    const demandeVue = demande.status.startsWith('Validee')
      ? 'Validee-vue'
      : demande.status.startsWith('Refusee')
      ? 'Refusee-vue'
      : null;
    const listeMarquerVue = copieLocalDemandesModification.map((element) => {
      if (element === demande) {
        return {
          status: demandeVue,
          auteur: element.auteur,
          date: element.date,
          texte: element.texte,
          reponse: element.reponse
        };
      }
      return element;
    });
    const formDataAEnvoyer = {
      demandes_modification: listeMarquerVue
    };
    createFichegrue(id, formDataAEnvoyer, navigate, false, false, false);
  };

  useEffect(() => {
    if (loading === false && fichegrue !== null) {
      setCopieLocalDemandesModification([...fichegrue.demandes_modification]);
    } else {
      setCopieLocalDemandesModification([]);
    }
  }, [loading, fichegrue]);
  return (
    <Fragment>
      {/* Vérification de la liste de copies des demandes de modification n'est pas vide. Si elle n'est pas vide, on la map. */}
      {copieLocalDemandesModification.length > 0 &&
        copieLocalDemandesModification.map((demandeModification) =>
          // Si l'utilisateur a les droits "admin", affichez le bandeau en lui indiquant que c'est d'administrateur, en lui fournissant la liste et en lui transmettant la fonction permettant de valider ou de refuser.
          auth.user.rights === 'admin' ? (
            <div key={demandeModification._id}>
              <BandeauDemandes
                isAdmin={auth.user.rights === 'admin'}
                demandeModification={demandeModification}
                demandeCompletee={retourAdminDemande}
              />
            </div>
          ) : demandeModification.auteur !== undefined &&
            demandeModification.auteur !== null ? (
            demandeModification.auteur._id === auth.user._id && // Si il n'as pas les droits "admin",on vérifie que la demande se trouvant sur cette fiche est bien celle de l'utilisateur connecté et que la demande de modification ne contient pas le mot "vue" dans son statut.
            !demandeModification.status.includes('vue') ? (
              <div key={demandeModification._id}>
                {/*Nous affichons le bandeau en lui indiquant qu'il n'est pas administrateur, en lui transmettant la liste des demandes de modification, ainsi que les fonctions lui permettant d'annuler sa demande de modification et de la marquer comme vue. */}
                <BandeauDemandes
                  isAdmin={auth.user.rights === 'admin'}
                  demandeModification={demandeModification}
                  annulerDemande={() =>
                    AnnulerDemandeModification(demandeModification)
                  }
                  marquerVue={() => DemandeVue(demandeModification)}
                />
              </div>
            ) : null
          ) : null
        )}
    </Fragment>
  );
};
InteractionDemandesModification.propTypes = {
  auth: PropTypes.object.isRequired,
  fichegrue: PropTypes.object.isRequired,
  createFichegrue: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createFichegrue
})(InteractionDemandesModification);
