import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import {
  getFichegrueByCustomParameters,
  deleteFichegrue
} from '../../actions/fichegrue';
// import { FaArrowRight } from 'react-icons/fa';
import ResultatItem from './ResultatItem';
// import Accordion from 'react-bootstrap/Accordion';

const ResultatsRecherche = ({
  getFichegrueByCustomParameters,
  deleteFichegrue,
  fichegrue: { fichesgrues, loading },
  auth,
  location
}) => {
  // Le hook useLocation permet de récupérer les informations qui sont dans l'URL.
  const emplacement = useLocation();
  // Nous précisons que nous allons nous référer au pathname pour récupérer les informations dans l'URL.
  const { pathname } = emplacement;
  // On récupère tous les pathnames jusqu'au modèle, dans notre cas nous en récupérons 4 : "/#/resultat/marque/modele", en précisant que chaque pathname est séparé par un "/".
  // Attention, veuillez bien laisser les deux virgules afin que cela puisse correctement récupérer la marque et le modèle au bon endroit sur l'url.
  const [, , marque, modele, serialnumber] = pathname.split('/');

  const [nbFiche602, setnbFiche602] = useState(0);
  const [nbFiche603, setnbFiche603] = useState(0);
  const [displayFiche603, setdisplayFiche603] = useState(true);
  const [displayFiche602, setdisplayFiche602] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getFichegrueByCustomParameters(
      marque !== 'null' ? marque : undefined,
      modele !== 'null' ? modele : undefined,
      serialnumber !== 'null' ? serialnumber : undefined
    );
    console.log(modele);
  }, [getFichegrueByCustomParameters, marque, modele, serialnumber, refresh]);

  const EffacementFicheGrue = (ficheID) => {
    if (
      window.confirm(
        'Etes vous sur ? Cette opération ne peut pas être annulée !'
      )
    ) {
      deleteFichegrue(ficheID);
      setTimeout(() => {
        window.location.reload();
      }, 3000); //runs after 5sec
    }
  };

  const MettreAJourPageListeDrapeau = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    let compteur602 = 0;
    let compteur603 = 0;
    if (fichesgrues.length > 0) {
      fichesgrues.map((fg, index) =>
        fg.typemc600 === 602
          ? (compteur602 = compteur602 + 1)
          : (compteur603 = compteur603 + 1)
      );
      setnbFiche602(compteur602);
      setnbFiche603(compteur603);
    }
  }, [fichesgrues]);

  if (loading) {
    return <Spinner />;
  } else if (fichesgrues === null || fichesgrues.length === 0) {
    return (
      <div style={{ marginTop: 100, marginBottom: 50 }}>
        <h3 className="text-center">Aucune fiche grue n'a été trouvée</h3>
      </div>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Resultats recherche :{' '}
              {marque !== 'null' ? decodeURIComponent(marque) + ' / ' : ''}{' '}
              {modele !== 'null' ? decodeURIComponent(modele) + ' / ' : ''}{' '}
              {serialnumber !== 'null'
                ? decodeURIComponent(serialnumber) + ' / '
                : ''}{' '}
            </h4>

            {/* 
          {fichesgrues.length > 0 ? (
            <h4 className="p-2 text-center">
              {fichesgrues.length} fiches grues trouvées :{' '}
            </h4>
          ) : (
            <h4 className="p-2 text-center">Aucune fiches grue trouvée : </h4>
          )} */}
            <div
              className="col text-center"
              style={{ marginBottom: 40, marginTop: 40 }}
            >
              <button
                type="button"
                className={`btn ${
                  displayFiche602 === true ? 'btn-warning ' : 'btn-secondary '
                } text-center`}
                style={{ marginRight: 50 }}
                onClick={() => setdisplayFiche602(!displayFiche602)}
              >
                Fiches MC602{' '}
                <span className="badge badge-light">{nbFiche602}</span>
              </button>
              <button
                type="button"
                className={`btn ${
                  displayFiche603 === true ? 'btn-success ' : 'btn-secondary '
                } text-center`}
                style={{ marginLeft: 50 }}
                onClick={() => setdisplayFiche603(!displayFiche603)}
              >
                Fiches MC603{' '}
                <span className="badge badge-light">{nbFiche603}</span>
              </button>
            </div>
            <table
              className="table text-nowrap table-striped table-light table-header-group table-container"
              // className=" table-container"
              // style={{ marginTop: 20 }}
              border="1"
            >
              <thead>
                <tr className="sticky">
                  <th className="text-center" id="th2"></th>
                  <th className="text-center" id="th1"></th>
                  {/* <th className="text-center" id="th2"></th> */}
                  <th className="text-center" id="th76"></th>
                  <th className="text-center" id="th3" width="100">
                    Date
                  </th>
                  <th className="text-center " id="th5">
                    Marque
                  </th>
                  <th className="text-center" id="th6">
                    Modele
                  </th>
                  <th className="text-center" id="serie">
                    Serie
                  </th>
                  <th className="text-center" id="btn_effac"></th>
                  {/* <th className="text-center" id="type1">
                  Type1
                </th> */}
                  <th className="text-center" id="type">
                    Type
                  </th>
                  <th className="text-center" id="origine">
                    Origine
                  </th>
                  <th className="text-center" id="liaison">
                    Liaison
                  </th>
                  <th className="text-center" id="serialnumber">
                    Serial number
                  </th>
                  <th className="text-center" id="anneeprod">
                    Annee prod.
                  </th>
                  <th className="text-center" id="infovue">
                    Infovue
                  </th>
                  <th className="text-center" id="indexgrue">
                    Indexgrue
                  </th>
                  <th className="text-center" id="nbs">
                    nb dents
                  </th>
                  <th className="text-center" id="module">
                    module
                  </th>
                  {[...Array(40)].map((e, i) => {
                    return i > 0 ? (
                      <th className="text-center" key={i}>
                        {i * 10}
                      </th>
                    ) : (
                      <th width="400" key={`${i}-${e}`}>
                        Seq:
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {/* </table>
          <table
            className="table text-nowrap table-striped table-light table-header-group table-container"
            // className=" table-container"
            // style={{ marginTop: 20 }}
            border="1"
          > */}
              <tbody>
                {fichesgrues.map((fichegrue, index) =>
                  fichegrue.modele === 'kitbaseMC603' ||
                  fichegrue.modele === 'kitbaseMC602' ||
                  fichegrue.origine === 'internal' ? (
                    <div>fiche grue interne</div>
                  ) : (
                    <ResultatItem
                      displayFiche602={displayFiche602}
                      displayFiche603={displayFiche603}
                      key={fichegrue._id}
                      fichegrue={fichegrue}
                      index={index}
                      deleteFiche={() => EffacementFicheGrue(fichegrue._id)}
                      setRefresh={MettreAJourPageListeDrapeau}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        </Fragment>
      </section>
    );
  }
};

ResultatsRecherche.propTypes = {
  getFichegrueByCustomParameters: PropTypes.func.isRequired,
  deleteFichegrue: PropTypes.func.isRequired,
  fichegrue: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  fichegrue: state.fichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getFichegrueByCustomParameters,
  deleteFichegrue
})(ResultatsRecherche);
