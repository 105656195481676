import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUser, getUserById } from '../../actions/user';
import { setAlert } from '../../actions/alert';
import Spinner from '../layout/Spinner';
import { GrCircleInformation } from 'react-icons/gr';

const initialState = {
  name: '',
  email: '',
  phone: '',
  rights: '',
  entity: 'ascorel',
  password: '',
  password2: ''
};

const UserEdit = ({
  setAlert,
  auth,
  user: { user, loading },
  createUser,
  getUserById
}) => {
  const [formData, setFormData] = useState(initialState);
  const [action, setaction] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const creatingUser = useMatch('/create-user');

  useEffect(() => {
    if (!user && !creatingUser) {
      getUserById(id);
    }
    if (!loading) {
      if (!creatingUser) {
        const userData = { ...initialState };
        for (const key in user) {
          if (key in userData) userData[key] = user[key];
        }
        setFormData(userData);
      } else {
        if (auth.user.rights === 'admin_entity') {
          const userData = { ...initialState };
          userData.entity = auth.user.entity;
          userData.rights = 'user';
          setFormData(userData);
        }
      }
    }
  }, [loading, user, id, getUserById, creatingUser]);

  const { name, email, rights, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!creatingUser && password !== password2) {
      setAlert('Les mots de passe ne correspondent pas.', 'danger');
    } else {
      createUser(user ? user._id : null, formData);
    }
    setaction(true);
    if (auth.user.rights === 'user') {
      setTimeout(() => {
        navigate(`/user/${user._id}`);
      }, 1000);
    } else {
      if (creatingUser) {
        setTimeout(() => {
          navigate('/users');
        }, 5000);
      } else {
        setTimeout(() => {
          navigate(`/user/${user._id}`);
        }, 1000);
      }
    }
  };

  return (
    <section className="container">
      <Fragment>
        {action ? (
          <div>
            {action && creatingUser ? (
              <div
                className="card-header-color text-center"
                style={{ marginTop: '1rem' }}
              >
                <GrCircleInformation size={40} color="grey" />
                <p className="lead" style={{ marginTop: '1rem' }}>
                  Email activation envoyé !
                </p>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        ) : (
          <Fragment>
            {auth.user.rights === 'admin' ||
            auth.user.rights === 'admin_entity' ||
            auth.user.rights === 'user' ? (
              <Fragment>
                <h1 className="large text-ascorel">
                  {creatingUser ? (
                    <p>Creation utilisateur</p>
                  ) : (
                    <p>Modification utilisateur</p>
                  )}
                </h1>
                <p className="lead">
                  <i className="fas fa-user" />
                  {creatingUser ? (
                    <p> Veuillez renseigner les champs ci-dessous :</p>
                  ) : (
                    <p> Modification des paramètres du compte :</p>
                  )}
                </p>
                * = champs requis
                <form className="form" onSubmit={onSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="*Email"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                    />

                    <small className="form-text">Doit etre unique</small>
                  </div>
                  <div className="form-group">
                    <select
                      name="rights"
                      value={rights}
                      onChange={(e) => onChange(e)}
                      required
                    >
                      <option value="" disabled selected>
                        * Selectionner droit
                      </option>
                      {auth.user.rights === 'admin' ? (
                        <option value="admin">Administrateur </option>
                      ) : null}
                      {auth.user.rights === 'admin' ||
                      auth.user.rights === 'admin_entity' ? (
                        <option value="admin_entity">
                          Administrateur entité
                        </option>
                      ) : null}
                      <option value="user">Utilisateur</option>
                    </select>
                    <small className="form-text">Niveau d'accès</small>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="*Nom"
                      name="name"
                      value={name}
                      onChange={onChange}
                      required
                    />
                    <small className="form-text">
                      Choissisez un nom descriptif
                    </small>
                  </div>
                  {!creatingUser ? (
                    <div>
                      <div className="form-group">
                        <input
                          type="password"
                          placeholder="Modifier mot de passe"
                          name="password"
                          value={password}
                          onChange={(e) => onChange(e)}
                        />
                        <small className="form-text">
                          6 caractères minimum
                        </small>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          placeholder="Confirmer nouveau mot de passe"
                          name="password2"
                          value={password2}
                          onChange={(e) => onChange(e)}
                        />
                        <small className="form-text">
                          Repetez le nouveau mot de passe pour confirmation
                        </small>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {creatingUser ? (
                    <input
                      type="submit"
                      className="btn btn-primary my-1"
                      value="Créer"
                    />
                  ) : (
                    <input
                      type="submit"
                      className="btn btn-primary my-1"
                      value="Enregistrer"
                    />
                  )}
                  {creatingUser ? (
                    <Link className="btn btn-light my-1" to="/users">
                      Annuler
                    </Link>
                  ) : (
                    <Link className="btn btn-light my-1" to={`/user/${id}`}>
                      Annuler
                    </Link>
                  )}
                </form>
              </Fragment>
            ) : (
              <p>Accès non autorisé</p>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

UserEdit.propTypes = {
  createUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createUser,
  getUserById,
  setAlert
})(UserEdit);
