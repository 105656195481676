import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import React from 'react';

const editStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'white'
  }
};

export default function ModalReponseAdmin({
  show,
  reponseDemande,
  closeModalAdmin,
  demandeCompletee
}) {
  function handleTextarea(event) {
    reponseDemande(event.target.value);
  }
  function handleSubmit(e) {
    e.preventDefault();
    demandeCompletee();
    closeModalAdmin();
  }
  return (
    <React.Fragment>
      <Modal style={editStyles} show={show} closeModalAdmin={closeModalAdmin}>
        <div>
          <Modal.Header style={{ display: 'flex', justifyContent: 'center' }}>
            <Modal.Title
              style={{ color: 'var(--ascorel-color)', fontSize: '35px' }}
            >
              Demande de modification :
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <textarea
                rows="8"
                placeholder="Veuillez décrire votre choix (facultatif)."
                style={{ padding: '3px' }}
                onChange={handleTextarea}
              ></textarea>
              <div
                className="float-right"
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="primary"
                  className="btn btn-primary btn-lg"
                  type="submit"
                  style={{
                    marginTop: 20,
                    marginBottom: 50
                  }}
                >
                  Envoyer
                </Button>
                <Button
                  variant="secondary"
                  className="btn btn-lg"
                  type="submit"
                  style={{ marginTop: 20, marginBottom: 50 }}
                  onClick={closeModalAdmin}
                >
                  Annuler
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
}
