import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { MdPlaylistAdd } from 'react-icons/md';
import { clearFichegrue } from '../../actions/fichegrue';
import { getDistinctRefreshAll } from '../../actions/fichegrue';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router-dom';

const listparam = [
  'marque',
  'modele',
  'serialnumber',
  'type1',
  'origine',
  'liaison',
  'serie',
  'anneeprod',
  'infovue',
  'indexgrue',
  'nbs'
];

const PH_MARQUE = 'Marque';
const PH_MODELE = 'Modele';
const PH_SERIALNB = 'Serial number';
const PH_TYPE1 = 'Type 1';
const PH_ORIGINE = 'Origine';
const PH_LIAISON = 'Liaison';
const PH_SERIE = 'Série';
const PH_ANNEEPROD = 'Année prod.';
const PH_INFOVUE = 'Info vue';
const PH_INDEX = 'Index';
const PH_NBS = 'nbs';

const RechercheFicheGrue = ({
  clearFichegrue,
  getDistinctRefreshAll,
  auth,
  fichegrue: { distincts, loading }
}) => {
  useEffect(() => {
    getDistinctRefreshAll();
  }, [clearFichegrue, getDistinctRefreshAll]);

  // array.filter((arrayItem) => arrayItem !== valueForRemove);

  // on aurait put avoir un tab de tab pour selectionArray et se passer de singleSelections, mais l'un doit etre null au debut et l'autre tjrs remplie au moins azvec PH
  //On se sert des PH juste pour avoir une majuscule au chargement de la page, sinon inutile
  const [selectionArray, setSelectionArray] = useState([
    PH_MARQUE,
    PH_MODELE,
    PH_SERIALNB,
    PH_TYPE1,
    PH_ORIGINE,
    PH_LIAISON,
    PH_SERIE,
    PH_ANNEEPROD,
    PH_INFOVUE,
    PH_INDEX,
    PH_NBS
  ]);
  const [singleSelections, setSingleSelections] = useState([[], []]);

  function onSelection(tab, index) {
    let arrayTampon = [...singleSelections];
    arrayTampon[index] = tab;
    setSingleSelections(arrayTampon);
    let newArr = [...selectionArray];
    tab.length > 0
      ? (newArr[index] = tab[0])
      : (newArr[index] = listparam[index]);
    setSelectionArray(newArr); //ne peut pas avoir des elts null
    getDistinctRefreshAll(
      newArr[0] !== PH_MARQUE && newArr[0] !== listparam[0]
        ? newArr[0]
        : undefined,
      newArr[1] !== PH_MODELE && newArr[1] !== listparam[1]
        ? newArr[1]
        : undefined,
      newArr[2] !== PH_SERIALNB && newArr[2] !== listparam[2]
        ? newArr[2]
        : undefined,
      newArr[3] !== PH_TYPE1 && newArr[3] !== listparam[3]
        ? newArr[3]
        : undefined,
      newArr[4] !== PH_ORIGINE && newArr[4] !== listparam[4]
        ? newArr[4]
        : undefined,
      newArr[5] !== PH_LIAISON && newArr[5] !== listparam[5]
        ? newArr[5]
        : undefined,
      newArr[6] !== PH_SERIE && newArr[6] !== listparam[6]
        ? newArr[6]
        : undefined,
      newArr[7] !== PH_ANNEEPROD && newArr[7] !== listparam[7]
        ? newArr[7]
        : undefined,
      newArr[8] !== PH_INFOVUE && newArr[8] !== listparam[8]
        ? newArr[8]
        : undefined,
      newArr[9] !== PH_INDEX && newArr[9] !== listparam[9]
        ? newArr[9]
        : undefined,
      newArr[10] !== PH_NBS && newArr[10] !== listparam[10]
        ? newArr[10]
        : undefined
    );
  }

  const navigate = useNavigate();

  const routeChange = () => {
    console.log('selectionArray used', selectionArray);
    if (
      selectionArray[0] !== PH_MARQUE ||
      selectionArray[1] !== PH_MODELE ||
      selectionArray[2] !== PH_SERIALNB
    ) {
      let path =
        `/resultats/` +
        (selectionArray[0] !== PH_MARQUE
          ? `${encodeURIComponent(selectionArray[0])}/`
          : `null/`) +
        (selectionArray[1] !== PH_MODELE
          ? `${encodeURIComponent(selectionArray[1])}/`
          : `null/`) +
        (selectionArray[2] !== PH_SERIALNB
          ? `${encodeURIComponent(selectionArray[2])}/`
          : `null/`);
      navigate(path);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        console.log('Enter key was pressed. Run your function.');
        event.preventDefault();
        routeChange();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [selectionArray]);

  //Suppression du spinner sur loading pour eviter de refresh la page quand on debute l'effacement d'un element dans le typeahaed
  if (distincts.length === 0) {
    return <Spinner />;
  } else {
    return (
      <section className="container">
        <div>
          <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
            Recherche fiche grue
          </h4>
          <div className="row text-center" style={{ marginTop: 30 }}>
            <div className="row  col-xs-6" style={{ margin: 10 }}>
              {listparam.map((elt, index) =>
                index < 3 ? (
                  <div>
                    <Typeahead
                      style={{ margin: 10 }}
                      id={`${elt}`}
                      labelKey={elt}
                      onChange={(tab) => onSelection(tab, index)}
                      options={distincts[index]}
                      placeholder={selectionArray[index]}
                      selected={singleSelections[index]}
                    />
                  </div>
                ) : (
                  <div key={index}></div>
                )
              )}
            </div>

            <div className=" text-center col-xs-6" style={{ margin: 10 }}>
              {/* TODO refactorer avec une map  */}
              {/* la partie .replace('/','|||') permet de filtrer les caracters / et de les remplacer poar des caracters ||| d'echaeppement, pour eviter les pb d'url
          on recupere ensuite ||| dans la page resultat et on le retransforme en / avant de lancer le get fichegrue*/}
              <div className="row">
                <div className="col text-center">
                  <button
                    style={btnStyle}
                    className="btn btn-primary btn-lg center-block"
                    onClick={routeChange}
                  >
                    Rechercher <FaSearch />
                  </button>

                  <Link to="/creation/0" className="text-navbar">
                    <button
                      style={btnStyle}
                      className="btn btn-primary btn-lg center-block"
                    >
                      Creer <MdPlaylistAdd />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

RechercheFicheGrue.propTypes = {
  clearFichegrue: PropTypes.func.isRequired,
  getDistinctRefreshAll: PropTypes.func.isRequired,
  fichegrue: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  fichegrue: state.fichegrue
});

export default connect(mapStateToProps, {
  clearFichegrue,
  getDistinctRefreshAll
})(RechercheFicheGrue);

const btnStyle = {
  margin: '8px',
  padding: '8px'
};
