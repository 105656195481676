import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import { GoDotFill } from 'react-icons/go';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
const Buffer = require('buffer/').Buffer;

// var imageName = require('../../img/Check_green_icon.svg.png');

// Si onDelete est different de null, on est sur le component CreateOption
const ItemListePieces = ({ eltBom, onDelete, affVariante }) => {
  let base64ImageString;
  if (eltBom.piece.vignette) {
    base64ImageString = Buffer.from(
      eltBom.piece.vignette.img.data.data,
      'binary'
    ).toString('base64');
  }

  return (
    <Fragment>
      {eltBom === undefined || eltBom === null ? (
        <Fragment>
          <td></td>
          <td></td>
          <td colSpan="1"></td>
        </Fragment>
      ) : (
        <Fragment>
          <td className="text-center" colSpan="1">
            {eltBom.seq}
          </td>
          <td
            className="text-center"
            style={{
              textDecorationLine: eltBom.piece.deleted ? 'line-through' : ''
            }}
          >
            <Link to={`/piece/${eltBom.piece._id}`}>{eltBom.piece.nom}</Link>
          </td>
          <td
            className="text-center"
            style={{
              textDecorationLine: eltBom.piece.deleted ? 'line-through' : ''
            }}
          >
            {eltBom.piece.commentaire}
          </td>
          <td
            className="text-center"
            style={{
              textDecorationLine: eltBom.piece.deleted ? 'line-through' : ''
            }}
          >
            {eltBom.piece.refx3}
          </td>
          <td className="text-center" colSpan="1">
            {eltBom.qte}
          </td>
          {eltBom.piecesjointes && eltBom.piecesjointes.length > 0 ? (
            <td class="text-center">
              <ListePiecesJointes
                tailleTexte="small"
                listPJ={eltBom.piecesjointes}
              />
            </td>
          ) : (
            <td className="text-center">
              <div> </div>
            </td>
          )}
          {/* <td className="text-center" colspan="1"> */}
          <td className="text-center">
            {eltBom.piece.vignette ? (
              <div>
                <img
                  className="vignette-list"
                  src={`data:${eltBom.piece.vignette.img.contentType};base64,${base64ImageString}`}
                  alt="vignette"
                />
              </div>
            ) : (
              <div> - </div>
            )}
          </td>

          {onDelete ? (
            <td className="text-center" colSpan="1">
              <button className="btn btn-warning" onClick={onDelete}>
                <MdDelete />
              </button>
            </td>
          ) : (
            <Fragment></Fragment>
          )}
          {eltBom.variantepossible && eltBom.variantepossible === true ? (
            <td className="text-center">
              <GoDotFill />
            </td>
          ) : (
            <Fragment>
              {affVariante ? (
                <td className="text-center">
                  <div> </div>
                </td>
              ) : (
                <Fragment></Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ItemListePieces.propTypes = {
  piece: PropTypes.object.isRequired,
  qty: PropTypes.object.isRequired
};

export default ItemListePieces;
