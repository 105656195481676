import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getPiecesByPartialText, getPieces } from '../../actions/piece';
import ResultatPieceItem from './ResultatPieceItem';
import { useParams } from 'react-router-dom';

const ResultatsRecherchePiece = ({
  getPiecesByPartialText,
  getPieces,
  piece: { pieces, loadingpiece }
}) => {
  const { partial } = useParams();

  useEffect(() => {
    if (partial) {
      if (partial === 'ALL_PIECES') {
        getPieces();
      } else {
        getPiecesByPartialText(partial.replace('|||', '/'));
      }
    }
  }, [getPiecesByPartialText, getPieces, partial]);

  const [nbPieces602, setnbPieces602] = useState(0);
  const [nbPieces603, setnbPieces603] = useState(0);
  const [nbPiecesDeleted, setnbPiecesDeleted] = useState(0);
  const [displayPiece603, setdisplayPiece603] = useState(true);
  const [displayPiece602, setdisplayPiece602] = useState(true);
  const [displayPieceDeleted, setdisplayPieceDeleted] = useState(true);
  const [sortedPieces, setSortedPieces] = useState([]);

  useEffect(() => {
    let compteur602 = 0;
    let compteur603 = 0;
    if (pieces.length > 0) {
      pieces.map((pc, index) =>
        pc.typemc600 === 602
          ? (compteur602 = compteur602 + 1)
          : (compteur603 = compteur603 + 1)
      );
      const count = pieces.filter((pc) => pc.deleted === true).length;
      setnbPieces602(compteur602);
      setnbPieces603(compteur603);
      setnbPiecesDeleted(count);
    }
    const clonepieces = pieces.map((i) => ({ ...i }));
    clonepieces.sort((a, b) => a.typemc600 - b.typemc600);
    setSortedPieces(clonepieces);
  }, [pieces]);

  if (loadingpiece) {
    return <Spinner />;
  } else if (pieces === null || pieces.length === 0) {
    return (
      <div style={{ marginTop: 100, marginBottom: 50 }}>
        <h3 className="text-center">
          Aucune piece contenant {partial} n'a été trouvée
        </h3>
      </div>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Resultats recherche : {partial !== 'null' ? partial : ''}{' '}
            </h4>
            <div
              className="col text-center"
              style={{ marginBottom: 40, marginTop: 40 }}
            >
              <button
                type="button"
                class={`btn ${
                  displayPiece602 === true ? 'btn-warning ' : 'btn-secondary '
                } text-center`}
                style={{ marginRight: 50 }}
                onClick={() => setdisplayPiece602(!displayPiece602)}
              >
                Pieces MC602{' '}
                <span className="badge badge-light">{nbPieces602}</span>
              </button>
              <button
                type="button"
                class={`btn ${
                  displayPiece603 === true ? 'btn-success ' : 'btn-secondary '
                } text-center`}
                style={{ marginLeft: 50, marginRight: 50 }}
                onClick={() => setdisplayPiece603(!displayPiece603)}
              >
                Pieces MC603{' '}
                <span className="badge badge-light">{nbPieces603}</span>
              </button>
              <button
                type="button"
                className={`btn ${
                  displayPieceDeleted === true
                    ? 'btn-danger '
                    : 'btn-secondary '
                } text-center`}
                style={{ marginLeft: 50 }}
                onClick={() => setdisplayPieceDeleted(!displayPieceDeleted)}
              >
                Pieces effacées{' '}
                <span className="badge badge-light">{nbPiecesDeleted}</span>
              </button>
            </div>
            <table
              className="table text-nowrap table-striped table-light table-header-group table-container"
              // className=" table-container"
              // style={{ marginTop: 20 }}
              border="1"
            >
              <thead>
                <tr className="sticky">
                  <th className="text-center"></th>
                  {/* <th className="text-center" id="th2"></th> */}
                  <th className="text-center"></th>

                  <th className="text-center ">Nom</th>
                  <th className="text-center">Reference X3</th>
                  <th className="text-center" width="50">
                    Vignette
                  </th>
                  <th className="text-center" width="50">
                    Sequences
                  </th>
                  {/* <th className="text-center" id="origine">
                    date creation
                  </th> */}
                  <th className="text-center">date modification</th>
                  {/* <th className="text-center" id="commentaire">
                    Commentaire
                  </th> */}
                  <th className="text-center">Pièces jointes</th>
                </tr>
              </thead>
              <tbody>
                {sortedPieces.map((piece, index) => (
                  <ResultatPieceItem
                    displayPiece602={displayPiece602}
                    displayPiece603={displayPiece603}
                    displayPieceDeleted={displayPieceDeleted}
                    key={piece._id}
                    piece={piece}
                    index={index}
                    // deleteFiche={() => EffacementFicheGrue(fichegrue._id)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      </section>
    );
  }
};

ResultatsRecherchePiece.propTypes = {
  getPiecesByPartialText: PropTypes.func.isRequired,
  getPieces: PropTypes.func.isRequired,
  // deleteFichegrue: PropTypes.func.isRequired,
  piece: PropTypes.object.isRequired
  // auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  piece: state.piece
  // auth: state.auth
});

export default connect(mapStateToProps, {
  getPiecesByPartialText,
  getPieces
  // deleteFichegrue
})(ResultatsRecherchePiece);
