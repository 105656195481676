import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getOptionById,
  deleteOption,
  createOption
} from '../../actions/optionfichegrue';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { MdRestoreFromTrash } from 'react-icons/md';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import ItemListePieces from '../fichegrue/ItemListePieces';

import Spinner from '../layout/Spinner';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import Moment from 'react-moment';
import { useParams, useNavigate } from 'react-router-dom';

const DetailOption = ({
  getOptionById,
  deleteOption,
  createOption,
  optionfichegrue: { option, loadingopt },
  auth
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getOptionById(id);
  }, [getOptionById, id]);

  const EffacementOption = () => {
    if (window.confirm('Etes vous sur de vouloir supprimer cette pièce ?')) {
      setaction(true);
      createOption(option._id, { deleted: true }, navigate);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
  };

  const RestaurerOption = () => {
    if (window.confirm('Etes vous sur de vouloir restaurer cette pièce ?')) {
      setaction(true);
      createOption(option._id, { deleted: false }, navigate);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
  };

  const [action, setaction] = useState(false);

  if (loadingopt || option === null || action) {
    return <Spinner />;
  } else {
    // console.log(testOptions);
    const {
      _id,
      nom,
      refx3,
      commentaire,
      typemc600,
      piecesjointes,
      bom,
      datecreation,
      datemodification,
      deleted
    } = option;

    return (
      <section className="container">
        <Fragment>
          <Card>
            <div style={{ marginBottom: 1 }}>
              <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                Détails Option
              </h4>
              {deleted ? (
                <div style={headStyleDeleted}>Option SUPPRIMEE</div>
              ) : (
                <div></div>
              )}

              <div style={{ marginLeft: 20, marginTop: 20 }}>
                {nom !== undefined ? (
                  <p>
                    <span className="text-center ">
                      <b>Nom :</b>
                    </span>{' '}
                    <span
                      className="text-center text-primary"
                      style={{
                        textDecorationLine: deleted ? 'line-through' : ''
                      }}
                    >
                      {nom}
                    </span>
                  </p>
                ) : (
                  <p></p>
                )}
                {refx3 !== undefined ? (
                  <p>
                    <span className="text-center ">
                      <b>Référence X3 :</b>
                    </span>{' '}
                    <span
                      className="text-center text-primary"
                      style={{
                        textDecorationLine: deleted ? 'line-through' : ''
                      }}
                    >
                      {refx3}
                    </span>
                  </p>
                ) : (
                  <p></p>
                )}
                {typemc600 !== undefined ? (
                  <p>
                    <span className="text-center ">
                      <b>Type MC :</b>
                    </span>{' '}
                    <span
                      className="text-center text-primary"
                      style={{
                        textDecorationLine: deleted ? 'line-through' : ''
                      }}
                    >
                      {typemc600}
                    </span>
                  </p>
                ) : (
                  <p></p>
                )}
                {commentaire !== undefined ? (
                  <p>
                    <span className="text-center ">
                      <b>Commentaire :</b>
                    </span>{' '}
                    <span
                      className="text-center text-primary"
                      style={{
                        textDecorationLine: deleted ? 'line-through' : ''
                      }}
                    >
                      {commentaire}
                    </span>
                  </p>
                ) : (
                  <p></p>
                )}
                {datecreation !== undefined ? (
                  <p>
                    <span className="text-center ">
                      <b>Date création :</b>
                    </span>{' '}
                    <Moment
                      className="text-center text-primary"
                      format="DD/MM/YYYY HH:mm"
                      style={{
                        textDecorationLine: deleted ? 'line-through' : ''
                      }}
                    >
                      {moment.utc(datecreation).local()}
                    </Moment>
                  </p>
                ) : (
                  <p></p>
                )}
                {datemodification !== undefined ? (
                  <p>
                    <span className="text-center ">
                      <b>Date modification :</b>
                    </span>{' '}
                    <Moment
                      className="text-center text-primary"
                      format="DD/MM/YYYY HH:mm"
                      style={{
                        textDecorationLine: deleted ? 'line-through' : ''
                      }}
                    >
                      {moment.utc(datemodification).local()}
                    </Moment>
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </Card>

          <div style={{ marginTop: 50 }} className="card">
            <div style={headStyle} className="card-header">
              BOM :
            </div>
            {bom.length > 0 ? (
              <div>
                {/* <h4 className="p-1  bg-ascorel text-white text-center">
                Liste materiel
              </h4> */}
                <table
                  className="table table-striped table-light table-header-group"
                  // style={{ marginTop: 20 }}
                  border="1"
                >
                  <thead>
                    <tr className="title-td">
                      <td id="th1" width="10px">
                        Seq
                      </td>
                      <td className="text-center" id="th1">
                        Designation
                      </td>
                      <td className="text-center" id="th2">
                        Commentaire
                      </td>
                      <td className="text-center" id="th3">
                        Reference X3
                      </td>
                      <td className="text-center" id="th3" width="10px">
                        Qté
                      </td>
                      <td className="text-center" id="th4">
                        P.J
                      </td>
                      <td className="text-center" id="th5">
                        Vignette
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {bom.map((elt, index) =>
                      elt.piece === null ? (
                        <div></div>
                      ) : (
                        <tr>
                          <ItemListePieces
                            eltBom={elt}
                            onDelete={null}
                            affVariante={false}
                          />
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>BOM vide ...</div>
            )}
          </div>

          <div style={{ marginTop: 50 }} className="card">
            <div style={headStyle} className="card-header">
              Pièces jointes :
            </div>
            <ListePiecesJointes tailleTexte="normal" listPJ={piecesjointes} />
          </div>

          <div style={{ marginTop: 50 }} className="row ">
            <div className="col-12 text-center ">
              {!deleted ? (
                <div>
                  <Link to={`/creationoption/${_id}`}>
                    <button className="btn btn-primary btn-lg">
                      Modifier option <MdOutlineModeEditOutline />
                    </button>
                  </Link>

                  <button
                    className="btn btn-warning btn-lg"
                    onClick={EffacementOption}
                  >
                    Effacer option <MdDelete />
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-success btn-lg"
                  onClick={RestaurerOption}
                >
                  Restaurer option <MdRestoreFromTrash />
                </button>
              )}
            </div>
          </div>
        </Fragment>
      </section>
    );
  }
};

DetailOption.propTypes = {
  auth: PropTypes.object.isRequired,
  optionfichegrue: PropTypes.object.isRequired,
  deleteOption: PropTypes.func.isRequired,
  createOption: PropTypes.func.isRequired,
  getOptionById: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  optionfichegrue: state.optionfichegrue,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getOptionById,
  deleteOption,
  createOption
})(DetailOption);

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
const headStyleDeleted = {
  fontSize: '20px',
  background: '#dc3545',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
